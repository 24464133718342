"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDocumentBody = exports.getClosestViewArea = exports.getClosestRow = exports.getClosestPopup = exports.getClosestButton = exports.getClosestSelectionTable = exports.getClosestTableCell = exports.getClosestTableRow = exports.getClosestTableBody = exports.getClosestCardBody = exports.getClosestCardHeader = exports.getDirectParent = void 0;
function getDirectParent(trigger) {
    return trigger.parentElement;
}
exports.getDirectParent = getDirectParent;
function getClosestCardHeader(trigger) {
    return trigger.closest('.ant-card-head');
}
exports.getClosestCardHeader = getClosestCardHeader;
function getClosestCardBody(trigger) {
    return trigger.closest('.ant-card-body');
}
exports.getClosestCardBody = getClosestCardBody;
function getClosestTableBody(trigger) {
    return trigger.closest('.ant-table-tbody');
}
exports.getClosestTableBody = getClosestTableBody;
function getClosestTableRow(trigger) {
    return trigger.closest('tr');
}
exports.getClosestTableRow = getClosestTableRow;
function getClosestTableCell(trigger) {
    return trigger.closest('td');
}
exports.getClosestTableCell = getClosestTableCell;
function getClosestSelectionTable(trigger) {
    return trigger.closest('.selection-table');
}
exports.getClosestSelectionTable = getClosestSelectionTable;
function getClosestButton(trigger) {
    return trigger.closest('button');
}
exports.getClosestButton = getClosestButton;
function getClosestPopup(trigger) {
    return trigger.closest('#ant-popover');
}
exports.getClosestPopup = getClosestPopup;
function getClosestRow(trigger) {
    return trigger.closest('.ant-row');
}
exports.getClosestRow = getClosestRow;
function getClosestViewArea(trigger) {
    return trigger.closest('.view-area');
}
exports.getClosestViewArea = getClosestViewArea;
function getDocumentBody() {
    return document.body;
}
exports.getDocumentBody = getDocumentBody;
