"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationOverview = void 0;
require("./ApplicationOverview.scss");
const ApplicationOverviewActions_1 = require("./ApplicationOverviewActions");
const icons_1 = require("@ant-design/icons");
const selectors_1 = require("@lib/selectors");
const DetectedAppNotice_1 = require("./DetectedAppNotice");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const GraphZoomButtons_1 = require("@pulse/system-overview/GraphZoomButtons");
const react_router_dom_1 = require("react-router-dom");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const general_1 = require("@ui/general");
const react_if_1 = require("react-if");
const OverviewGraph_1 = require("@pulse/system-overview/OverviewGraph");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const graph_tools_1 = require("@pulse/graph-tools");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function buildImage(appId, app) {
    return (0, OverviewGraph_1.buildImageMetadata)(appId, app.name, app.label);
}
function buildEdges(entries, lineType) {
    return entries.map((entry) => (0, OverviewGraph_1.buildEdge)(entry.read, entry.write, lineType, 'app', String(entry.sonarObjectId)));
}
function buildNodes(entries, appMetadata) {
    const maxHeight = 56;
    let centerNode = {
        anchorPoints: OverviewGraph_1.anchorPoints,
        id: 'app'
    };
    if (appMetadata.path) {
        centerNode = Object.assign(Object.assign({}, centerNode), { cursor: 'pointer', img: appMetadata.path, size: [(appMetadata.width || 1) * (maxHeight / (appMetadata.height || maxHeight)), maxHeight], type: 'app-center-display-image' });
    }
    else {
        centerNode = Object.assign(Object.assign({}, centerNode), { label: appMetadata.name, labelCfg: {
                style: {
                    background: {
                        fill: '#FAFAFA',
                        padding: [8, 6],
                        radius: 2,
                        stroke: '#D9D9D9'
                    },
                    cursor: 'pointer',
                    fill: 'rgba(0,0,0,0.88)',
                    font: 'inter',
                    fontSize: 48
                }
            }, size: [(0, OverviewGraph_1.getTextWidth)(appMetadata.name, '48pt inter'), 80], style: {
                cursor: 'pointer',
                fill: '',
                stroke: ''
            }, type: 'rect' });
    }
    return [
        centerNode,
        ...entries.map((entry) => {
            const nodeWidth = (0, OverviewGraph_1.getTextWidth)(entry.displayName, '21pt inter');
            return {
                id: String(entry.sonarObjectId),
                label: entry.displayName,
                size: [nodeWidth, maxHeight],
                type: 'app-object'
            };
        })
    ];
}
const appObjectsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.applications.objects.get);
exports.ApplicationOverview = (0, react_1.memo)(({ applicationId }) => {
    var _a;
    const displayTools = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.PulseGraphTools);
    const [layoutType, setLayoutType] = (0, react_1.useState)(graph_tools_1.GraphLayouts[7].value);
    const [lineType, setLineType] = (0, react_1.useState)(graph_tools_1.GraphLines[12].value);
    const [layoutOptions, setLayoutOptions] = (0, react_1.useState)(Object.assign(Object.assign({}, sonar_core_1.defaultForceOptions), { type: layoutType }));
    const [mockAppCount, setMockAppCount] = (0, react_1.useState)(0);
    const [maxZoom, setMaxZoom] = (0, react_1.useState)(2);
    const [minZoom, setMinZoom] = (0, react_1.useState)('0.4');
    const application = (0, _hooks_1.useApplicationReference)(applicationId);
    const nameDisplay = (_a = application.name) !== null && _a !== void 0 ? _a : applicationId;
    const labelDisplay = application.label ? ` (${application.label})` : '';
    const displayName = `${nameDisplay}${labelDisplay}`;
    const [appMetadata, setAppMetadata] = (0, react_1.useState)(null);
    const { data, isReceived } = (0, _hooks_1.useAsyncStateWithPreload)(appObjectsSelectors, sonar_core_1.coreActions.requestGetCorePulseApplicationsObjects, true, { applicationId });
    (0, react_1.useEffect)(() => {
        buildImage(applicationId, application).then((result) => {
            setAppMetadata(result);
        });
    }, [application, applicationId]);
    const isDetected = application.status === sonar_core_1.PulseApplicationStatus.Detected;
    const graphData = (0, react_1.useMemo)(() => (appMetadata ? {
        combos: [],
        edges: buildEdges(data !== null && data !== void 0 ? data : [], lineType),
        nodes: buildNodes(data !== null && data !== void 0 ? data : [], appMetadata)
    } : sonar_core_1.emptyGraphData), [appMetadata, data, lineType]);
    const isReady = isReceived && data && appMetadata;
    return (react_1.default.createElement(general_1.SonarCard, { extra: react_1.default.createElement(ApplicationOverviewActions_1.ApplicationOverviewActions, { applicationId: applicationId }), id: 'application-overview-card', title: react_1.default.createElement(antd_1.Space, { size: 'small' },
            react_1.default.createElement(react_router_dom_1.Link, { to: sonar_ts_constants_1.FULL_ROUTES.PULSE },
                react_1.default.createElement(antd_1.Button, { className: 'back-to-overview-button', icon: react_1.default.createElement(icons_1.ArrowLeftOutlined, null), type: 'link' })),
            react_1.default.createElement("div", { className: 'h2' }, displayName)) },
        react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { minDuration: 1000, opaque: true, spinning: !isReady },
            react_1.default.createElement(OverviewGraph_1.OverviewGraph, { data: isReady ? graphData : sonar_core_1.emptyGraphData, layoutOptions: layoutOptions, maxZoom: maxZoom, minZoom: Number(minZoom) },
                react_1.default.createElement(antd_1.Space, { direction: 'vertical', style: { width: '100%' } },
                    react_1.default.createElement(antd_1.Row, { align: 'bottom', justify: 'end' },
                        react_1.default.createElement(GraphZoomButtons_1.GraphZoomButtons, null)))),
            react_1.default.createElement(react_if_1.When, { condition: displayTools },
                react_1.default.createElement(antd_1.Space, null,
                    react_1.default.createElement(graph_tools_1.GraphLayoutSelector, { onChange: (value) => {
                            setLayoutOptions(Object.assign(Object.assign({}, layoutOptions), { type: value }));
                            setLayoutType(value);
                        }, value: layoutType }),
                    react_1.default.createElement(graph_tools_1.GraphLineSelector, { onChange: setLineType, value: lineType }),
                    react_1.default.createElement(antd_1.InputNumber, { min: 0, onChange: (value) => setMockAppCount(value !== null && value !== void 0 ? value : 0), value: mockAppCount }),
                    react_1.default.createElement(antd_1.InputNumber, { min: 0, onChange: (value) => setMaxZoom(value !== null && value !== void 0 ? value : 2), value: maxZoom }),
                    react_1.default.createElement(antd_1.InputNumber, { min: '0', onChange: (value) => setMinZoom(value !== null && value !== void 0 ? value : '0.4'), step: '0.1', stringMode: true, value: minZoom })),
                react_1.default.createElement(graph_tools_1.GraphConfigEditor, { onChange: setLayoutOptions, value: layoutOptions }))),
        isDetected && react_1.default.createElement(DetectedAppNotice_1.DetectedAppNotice, { applicationId: applicationId })));
});
