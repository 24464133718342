"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSalesforceLink = void 0;
const selectors_1 = require("@lib/selectors");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const useAction_1 = require("./useAction");
const usePrevious_1 = require("./usePrevious");
const useSonarSelector_1 = require("./useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = require("react");
const linkSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.organizations.link.post);
function useSalesforceLink() {
    const response = (0, useSonarSelector_1.useSonarSelector)(linkSelectors.data);
    const hasError = (0, useSonarSelector_1.useSonarSelector)(linkSelectors.isErrored);
    const hasFailure = (0, useSonarSelector_1.useSonarSelector)(linkSelectors.isFailed);
    const hasSuccess = (0, useSonarSelector_1.useSonarSelector)(linkSelectors.isReceived);
    const status = (0, useSonarSelector_1.useSonarSelector)(linkSelectors.statusCode);
    const previousHasError = (0, usePrevious_1.usePrevious)(hasError);
    const previousHasFailure = (0, usePrevious_1.usePrevious)(hasFailure);
    const previousHasSuccess = (0, usePrevious_1.usePrevious)(hasSuccess);
    const onResponseRef = (0, react_1.useRef)(null);
    const linkSalesforce = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreOrganizationsLink);
    const hasNewError = hasError && !previousHasError;
    const hasNewFailure = hasFailure && !previousHasFailure;
    const hasNewSuccess = hasSuccess && !previousHasSuccess;
    const shouldTriggerResponse = hasNewError || hasNewFailure || hasNewSuccess;
    (0, react_1.useEffect)(() => {
        if (onResponseRef.current && shouldTriggerResponse) {
            onResponseRef.current(response, status);
        }
    }, [response, shouldTriggerResponse, status]);
    (0, react_1.useEffect)(() => {
        if (response === null || response === void 0 ? void 0 : response.updatedUserAccessToken) {
            (0, sonar_ts_lib_1.setJWTToken)(response.updatedUserAccessToken);
        }
    }, [response === null || response === void 0 ? void 0 : response.updatedUserAccessToken]);
    const link = (0, react_1.useCallback)((options) => {
        const { code, organizationId, onResponse } = options;
        onResponseRef.current = onResponse !== null && onResponse !== void 0 ? onResponse : null;
        linkSalesforce({
            code,
            organizationId
        });
    }, [linkSalesforce]);
    return link;
}
exports.useSalesforceLink = useSalesforceLink;
