"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlag = exports.useFeatureFlags = void 0;
const hooks_1 = require("@lib/hooks");
const react_1 = require("react");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = [];
/**
 * Subscribes to and returns the collection of feature flags and helper method. Will load related
 * data upon mount if data is not available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns A {@link UseFeatureFlagsResult} instance containing the `featureFlag` collection as an array of strings
 * and an `isEnabled` helper method.
 */
exports.useFeatureFlags = (0, hooks_1.createAsyncStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCoreCustomersCurrentFeatureFlags,
    selector: (state) => state.core.customers.current.featureFlags.get,
    useTransform: (result) => {
        const { data } = result;
        const isEnabled = (0, react_1.useCallback)((flags, defaultResult = false) => {
            if (!flags || !(data === null || data === void 0 ? void 0 : data.length)) {
                return defaultResult;
            }
            return Array.isArray(flags) ? flags.some((flag) => data.includes(flag)) : data.includes(flags);
        }, [data]);
        return {
            featureFlags: data || empty,
            isEnabled
        };
    }
});
/**
 * Validates that the feature flag is active for the current customer. If provided an array of feature flags,
 * it will validate that at least one of the flags is active for the customer.
 * @param flag The specific `FeatureFlag` or array of `FeatureFlag` to validate.
 * @param defaultResult Boolean indicating the default return value should the feature flags are not loaded yet.
 * @returns Boolean indicating if the provided flag, or at least one of an array, is active.
 */
function useFeatureFlag(flag, defaultResult = false) {
    const { isEnabled } = (0, exports.useFeatureFlags)();
    return isEnabled(flag, defaultResult);
}
exports.useFeatureFlag = useFeatureFlag;
