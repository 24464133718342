"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeTabArea = exports.PeriscopeMenuTabs = void 0;
require("./PeriscopeMenuTabs.scss");
const icons_1 = require("@ant-design/icons");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const _hooks_1 = require("@hooks");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
function updateCountLabels(menuItem) {
    var _a;
    const submenu = menuItem;
    if (!((_a = submenu.children) === null || _a === void 0 ? void 0 : _a.length)) {
        return;
    }
    for (const child of submenu.children) {
        updateCountLabels(child);
    }
    const countChildren = submenu.children.filter((child) => !(0, sonar_ts_lib_1.isUndefined)(child.count));
    if (!countChildren.length) {
        return;
    }
    const sum = countChildren
        .map((child) => { var _a; return (_a = child.count) !== null && _a !== void 0 ? _a : 0; })
        .reduce((prev, curr) => prev + curr, 0);
    submenu.count = sum;
    submenu.label = (0, sonar_ts_lib_1.getPeriscopeTabDisplay)(submenu.label, sum);
}
function getEnabledChildren(children, isEnabled) {
    return react_1.default.Children.toArray(children).filter((child) => {
        if (!child.props.featureFlag) {
            return true;
        }
        return isEnabled(child.props.featureFlag);
    });
}
function buildMenuItems(children, setOpenKeys, activeKeyPathRef) {
    var _a;
    const results = [];
    const submenuMap = {};
    const groupMap = {};
    const onSubmenuClick = (info) => {
        if (info.key === activeKeyPathRef.current[0] && activeKeyPathRef.current.length > 1) {
            setTimeout(() => {
                setOpenKeys(activeKeyPathRef.current);
            }, 150);
        }
    };
    const onSubmenuGroupMouseEnter = (info) => {
        setOpenKeys((keys) => {
            if (keys.indexOf(info.key) === -1) {
                return [...keys, info.key];
            }
            return keys;
        });
    };
    const onSubmenuGroupMouseLeave = (info) => {
        setOpenKeys((keys) => {
            const index = keys.indexOf(info.key);
            if (index !== -1) {
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            }
            return keys;
        });
    };
    for (let i = 0; i < children.length; i += 1) {
        const { key, props } = children[i];
        const { count, disabled, group, requiresCount, submenu, tab } = props;
        const isDisabled = disabled || (requiresCount && !count);
        let targetSubmenu = undefined;
        let targetGroup = undefined;
        if (submenu) {
            targetSubmenu = (0, sonar_ts_lib_1.getOrAddEntry)(submenu, submenuMap, () => {
                const newSubmenu = {
                    children: [],
                    key: submenu,
                    label: submenu,
                    onTitleClick: onSubmenuClick,
                    popupClassName: 'periscope-tab-submenu',
                    popupOffset: [-10, 0]
                };
                results.push(newSubmenu);
                return newSubmenu;
            });
        }
        if (group) {
            targetGroup = (0, sonar_ts_lib_1.getOrAddEntry)(group, groupMap, () => {
                const newGroup = {
                    children: [],
                    key: group,
                    label: group,
                    onMouseEnter: onSubmenuGroupMouseEnter,
                    onMouseLeave: onSubmenuGroupMouseLeave,
                    popupOffset: [0, -4]
                };
                if (targetSubmenu) {
                    targetSubmenu.children.push(newGroup);
                }
                else {
                    results.push(newGroup);
                }
                return newGroup;
            });
        }
        if (targetSubmenu && targetGroup && !targetSubmenu.children.includes(targetGroup)) {
            targetSubmenu.children.push(targetGroup);
        }
        const entry = {
            count: count,
            disabled: isDisabled,
            key: key,
            label: (0, sonar_ts_lib_1.isUndefined)(count) ? tab : (0, sonar_ts_lib_1.getPeriscopeTabDisplay)(tab, count)
        };
        if (targetGroup) {
            (_a = targetGroup.children) === null || _a === void 0 ? void 0 : _a.push(entry);
        }
        else if (!targetGroup && targetSubmenu) {
            targetSubmenu.children.push(entry);
        }
        else {
            results.push(entry);
        }
    }
    for (const entry of results) {
        updateCountLabels(entry);
    }
    return results;
}
function buildTabItems(children) {
    const results = [];
    for (let i = 0; i < children.length; i += 1) {
        const { key, props } = children[i];
        const { forceRender } = props;
        results.push({
            children: props.children,
            forceRender,
            key: String(key),
            label: key
        });
    }
    return results;
}
const PeriscopeMenuTabs = ({ children, defaultKey }) => {
    const { isEnabled } = (0, _hooks_1.useFeatureFlags)();
    const [menuItems, setMenuItems] = (0, react_1.useState)([]);
    const [tabItems, setTabItems] = (0, react_1.useState)([]);
    const [activeKey, setActiveKey] = (0, react_1.useState)(defaultKey ? `.$${defaultKey}` : undefined);
    const [openKeys, setOpenKeys] = (0, react_1.useState)([]);
    const activeKeyPathRef = (0, react_1.useRef)([]);
    (0, _hooks_1.useOnMount)(() => {
        const availableChildren = getEnabledChildren(children, isEnabled);
        setMenuItems(buildMenuItems(availableChildren, setOpenKeys, activeKeyPathRef));
        setTabItems(buildTabItems(availableChildren));
    });
    return (react_1.default.createElement(antd_1.Tabs, { activeKey: activeKey, className: 'periscope-tab', items: tabItems, onChange: setActiveKey, renderTabBar: (props) => react_1.default.createElement("div", { className: 'ant-tabs-nav' },
            react_1.default.createElement(antd_1.Menu, { activeKey: props.activeKey, disabledOverflow: true, expandIcon: react_1.default.createElement(icons_1.ArrowRightOutlined, null), items: menuItems, mode: 'horizontal', onClick: (clickEvent) => {
                    props.onTabClick(clickEvent.key, clickEvent.domEvent);
                    activeKeyPathRef.current = clickEvent.keyPath.slice(1).reverse();
                }, onOpenChange: (keys) => setOpenKeys(keys), openKeys: openKeys, triggerSubMenuAction: 'click' })) }));
};
exports.PeriscopeMenuTabs = PeriscopeMenuTabs;
const PeriscopeTabArea = () => null;
exports.PeriscopeTabArea = PeriscopeTabArea;
