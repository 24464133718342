"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrevious = void 0;
const react_1 = require("react");
function usePrevious(value, initialValue) {
    const valueRef = (0, react_1.useRef)(initialValue);
    (0, react_1.useEffect)(() => {
        valueRef.current = value;
    });
    return valueRef.current;
}
exports.usePrevious = usePrevious;
