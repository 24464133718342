"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSwitchOrganization = void 0;
const _app_1 = require("@app");
const selectors_1 = require("@lib/selectors");
const utility_1 = require("@lib/utility");
const sonar_security_1 = require("@sonar-software/sonar-security");
const useAction_1 = require("../useAction");
const useSonarSelector_1 = require("@hooks/useSonarSelector");
const react_1 = require("react");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.switchOrganization.post);
function useSwitchOrganization() {
    const handlerRef = (0, react_1.useRef)(utility_1.noop);
    const softResetApp = (0, useAction_1.useAction)(_app_1.appActions.softResetApplication);
    const requestAction = (0, useAction_1.useAction)(sonar_security_1.securityActions.requestPostSecuritySwitchOrganization);
    const resetAction = (0, useAction_1.useAction)(sonar_security_1.securityActions.resetPostSecuritySwitchOrganization);
    const isSuccessful = (0, useSonarSelector_1.useSonarSelector)(selectors.isReceived);
    (0, react_1.useEffect)(() => {
        if (isSuccessful) {
            resetAction();
            handlerRef.current();
            softResetApp();
        }
    }, [isSuccessful, resetAction, softResetApp]);
    return (0, react_1.useCallback)((request, handler) => {
        handlerRef.current = handler || utility_1.noop;
        requestAction(request);
    }, [requestAction]);
}
exports.useSwitchOrganization = useSwitchOrganization;
