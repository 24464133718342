"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAction = void 0;
const react_redux_1 = require("react-redux");
const react_1 = require("react");
const redux_1 = require("redux");
function useAction(action) {
    const dispatch = (0, react_redux_1.useDispatch)();
    const boundAction = (0, react_1.useMemo)(() => (0, redux_1.bindActionCreators)(action, dispatch), [action, dispatch]);
    return boundAction;
}
exports.useAction = useAction;
