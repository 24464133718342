"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplianceCategorySelector = void 0;
/* eslint-disable no-bitwise */
require("./ComplianceCategorySelector.scss");
const icons_1 = require("@ui/icons");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const SonarSelector_1 = require("../SonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const enumOptions = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.ComplianceCategory)
    .filter((category) => category !== sonar_core_1.ComplianceCategory.Any);
function getValues(value) {
    const results = [];
    for (const option of enumOptions) {
        if (value & option) {
            results.push(option);
        }
    }
    return results.length ? results : [0];
}
function getRawValue(values) {
    return values.reduce((prev, curr) => curr + prev, 0);
}
function updateComplianceCategory(sonarObjectId, complianceCategory) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const result = yield (0, sonar_core_1.patchSalesforceSonarObject)({
            complianceCategory,
            sonarObjectId
        });
        return (_a = result.data) !== null && _a !== void 0 ? _a : _hooks_1.genericProcessingFailure;
    });
}
const ComplianceCategorySelector = (_a) => {
    var _b, _c;
    var { direction = 'vertical', isSpreadsheet, sonarObjectId } = _a, rest = __rest(_a, ["direction", "isSpreadsheet", "sonarObjectId"]);
    const { complianceCategory } = (_c = (_b = (0, _hooks_1.useSonarObjectReference)(sonarObjectId)) === null || _b === void 0 ? void 0 : _b.salesforce) !== null && _c !== void 0 ? _c : {};
    const { update } = (0, _hooks_1.useUpdateSonarObjectReference)();
    const { start, state, stop } = (0, _hooks_1.useProcessing)();
    const prevCategory = (0, _hooks_1.usePrevious)(complianceCategory, complianceCategory);
    const splitCategories = getValues(complianceCategory !== null && complianceCategory !== void 0 ? complianceCategory : sonar_core_1.ComplianceCategory.None);
    const [value, setValue] = (0, react_1.useState)(splitCategories);
    const originalValRef = (0, react_1.useRef)(value);
    const workingValRef = (0, react_1.useRef)(value);
    // Attempting to build this outside of the component results in compilation issues.
    const options = (0, react_1.useMemo)(() => enumOptions
        .map((category) => ({
        label: react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: category }),
        optionLabel: react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: category }),
        value: category
    })), []);
    (0, react_1.useEffect)(() => {
        if (complianceCategory !== undefined &&
            prevCategory !== complianceCategory &&
            complianceCategory !== getRawValue(value)) {
            setValue(getValues(complianceCategory));
        }
    }, [complianceCategory, prevCategory, value]);
    const handleBlur = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const mergedOriginal = getRawValue(originalValRef.current);
        const mergedNew = getRawValue(workingValRef.current);
        if (mergedNew === mergedOriginal) {
            return;
        }
        const fallback = originalValRef.current;
        start();
        const result = yield updateComplianceCategory(sonarObjectId, mergedNew);
        if (result.success) {
            originalValRef.current = workingValRef.current;
            update({
                salesforce: { complianceCategory: mergedNew },
                sonarObjectId
            });
        }
        else {
            setValue(fallback);
        }
        stop(result);
    }), [sonarObjectId, start, stop, update]);
    const handleClear = (0, react_1.useCallback)(() => {
        workingValRef.current = [sonar_core_1.ComplianceCategory.None];
        setValue(workingValRef.current);
        handleBlur();
    }, [handleBlur]);
    const handleChange = (0, react_1.useCallback)((change) => {
        let newValue;
        const changeIncludesNone = change.includes(sonar_core_1.ComplianceCategory.None);
        const valueIncludesNone = value.includes(sonar_core_1.ComplianceCategory.None);
        const changeHasOthers = change.some((changeValue) => changeValue !== sonar_core_1.ComplianceCategory.None);
        if ((changeIncludesNone && !valueIncludesNone) || !change.length) {
            newValue = [sonar_core_1.ComplianceCategory.None];
        }
        else if (valueIncludesNone && changeHasOthers) {
            newValue = change.filter((changeValue) => changeValue !== sonar_core_1.ComplianceCategory.None);
        }
        else {
            newValue = change;
        }
        workingValRef.current = newValue;
        setValue(newValue);
    }, [value]);
    return (react_1.default.createElement(SonarSelector_1.SonarSelector, Object.assign({}, rest, { allowClear: !value.includes(sonar_core_1.ComplianceCategory.None), boxless: !isSpreadsheet, className: 'salesforce-compliance-category-selector', defaultValue: splitCategories || [sonar_core_1.ComplianceCategory.None], direction: direction, isSalesforce: true, isSpreadsheet: isSpreadsheet, mode: 'multiple', onBlur: handleBlur, onChange: handleChange, onClear: handleClear, options: options, placeholder: react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: sonar_core_1.ComplianceCategory.None }), popupMatchSelectWidth: false, readOnlyDisplay: react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: complianceCategory || sonar_core_1.ComplianceCategory.None }), showArrow: true, showSearch: false, state: state, value: value })));
};
exports.ComplianceCategorySelector = ComplianceCategorySelector;
