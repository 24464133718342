"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBoolean = void 0;
const react_1 = require("react");
/**
 * Returns a stateful boolean value and an object containing functions to update it.
 *
 * @param defaultValue Initial value on mount.
 * @returns An array with the boolean `value` and {@link UseBooleanSetValue} functions object.
 */
function useBoolean(defaultValue) {
    const [value, setValue] = (0, react_1.useState)(Boolean(defaultValue));
    const on = (0, react_1.useCallback)(() => setValue(true), []);
    const off = (0, react_1.useCallback)(() => setValue(false), []);
    const toggle = (0, react_1.useCallback)(() => setValue((val) => !val), []);
    const useBooleanSetValue = (0, react_1.useMemo)(() => ({
        off,
        on,
        toggle
    }), [off, on, toggle]);
    return [value, useBooleanSetValue];
}
exports.useBoolean = useBoolean;
