"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTag = void 0;
const css = __importStar(require("css/exports.module.scss"));
const notifications_1 = require("@ui/notifications");
const popups_1 = require("@lib/popups");
const general_1 = require("@ui/general");
const TagDescriptionInput_1 = require("./TagDescriptionInput");
const TagNameInput_1 = require("./TagNameInput");
const TagOwnersInput_1 = require("./TagOwnersInput");
const _hooks_1 = require("@hooks");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const CreateTag = () => {
    const [hexColor, setHexColor] = (0, react_1.useState)(sonar_ts_constants_1.TAG_COLORS[0]);
    const formRef = (0, react_1.useRef)(null);
    const { isProcessing, isSuccessful, request, reset } = (0, _hooks_1.useCreateTag)();
    const handleOnSubmit = (values) => {
        const { description, name, userIds } = values;
        const ownerIds = (userIds === null || userIds === void 0 ? void 0 : userIds.length) ? userIds.map((id) => ({ userId: Number(id) })) : [];
        request({
            description: description !== null && description !== void 0 ? description : '',
            hexColor,
            name,
            ownerIds
        });
    };
    (0, react_1.useEffect)(() => {
        var _a;
        if (isSuccessful) {
            (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.resetFields();
            setHexColor(sonar_ts_constants_1.TAG_COLORS[0]);
        }
    }, [isSuccessful]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(general_1.SonarCard, { className: 'tag-create-card', title: sonar_ts_constants_1.COPY_SPECIFIC.CREATE_TAG },
            react_1.default.createElement(antd_1.Form, { className: 'create', onFinish: handleOnSubmit, ref: formRef },
                react_1.default.createElement(antd_1.Row, { gutter: [Number(css.size16), 0] },
                    react_1.default.createElement(antd_1.Col, { span: 6 },
                        react_1.default.createElement(TagNameInput_1.TagNameInput, { getPopupContainer: popups_1.getDirectParent, hexColor: hexColor, name: 'name', onColorClick: setHexColor })),
                    react_1.default.createElement(antd_1.Col, { span: 18 },
                        react_1.default.createElement(TagOwnersInput_1.TagOwnersInput, { name: 'userIds' }))),
                react_1.default.createElement(TagDescriptionInput_1.TagDescriptionInput, { name: 'description' }),
                react_1.default.createElement(antd_1.Row, null,
                    react_1.default.createElement(antd_1.Button, { className: 'tag-create-button', disabled: isProcessing, htmlType: 'submit', id: 'tag-create-button', loading: isProcessing, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.CREATE)))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_POST_TAG, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_POST_TAG, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_POST_TAG, resetAction: reset, stateSelector: _hooks_1.useCreateTag.selectors.state })));
};
exports.CreateTag = CreateTag;
