"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePeriscopeActions = void 0;
const _app_1 = require("@app");
const sonar_core_1 = require("@sonar-software/sonar-core");
const useAction_1 = require("../useAction");
const useAmplitude_1 = require("../useAmplitude");
const react_1 = require("react");
const useDataDictionaryWorkflow_1 = require("../useDataDictionaryWorkflow");
const useInitiativeWorkflow_1 = require("../initiatives/useInitiativeWorkflow");
const useIssuesWorkflow_1 = require("../useIssuesWorkflow");
const react_router_dom_1 = require("react-router-dom");
const useSonarSelector_1 = require("../useSonarSelector");
const useTimelineWorkflow_1 = require("../useTimelineWorkflow");
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
function usePeriscopeActions() {
    var _a;
    const navigate = (0, react_router_dom_1.useNavigate)();
    const shouldUseInitiativeWorkflow = (0, useInitiativeWorkflow_1.useInitiativeWorkflow)();
    const shouldUseIssuesWorkflow = (0, useIssuesWorkflow_1.useIssuesWorkflow)();
    const shouldUseTimelineWorkflow = (0, useTimelineWorkflow_1.useTimelineWorkflow)();
    const shouldUseDataDictionaryWorkflow = (0, useDataDictionaryWorkflow_1.useDataDictionaryWorkflow)();
    const addPeriscope = (0, useAction_1.useAction)(_app_1.appActions.updatePeriscope);
    const deletePeriscope = (0, useAction_1.useAction)(_app_1.appActions.removePeriscope);
    const updatePeriscopeAction = (0, useAction_1.useAction)(_app_1.appActions.updatePeriscopeInfo);
    const getInitiativesForObject = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestGetCoreSonarObjectsInitiatives);
    const logAnalyticEvent = (0, useAmplitude_1.useAmplitude)();
    const periscopes = (0, useSonarSelector_1.useSonarSelector)((state) => { var _a; return (_a = state.application.periscope) === null || _a === void 0 ? void 0 : _a.cards; });
    const periscopeLength = (_a = periscopes === null || periscopes === void 0 ? void 0 : periscopes.length) !== null && _a !== void 0 ? _a : 0;
    const goToPeriscope = (0, react_1.useCallback)((options) => {
        const { id, type } = options;
        navigate(`/${sonar_ts_constants_1.BASE_ROUTES.PERISCOPE}/${type}/${id}`);
    }, [navigate]);
    const openPeriscope = (0, react_1.useCallback)((options) => {
        var _a;
        options.id = isNaN(Number(options.id)) ? options.id : Number(options.id);
        options.type = (0, sonar_ts_lib_1.isNumber)(options.type) ?
            (0, sonar_ts_lib_1.getSonarObjectTypeFromId)(options.type) :
            options.type;
        const { id, openLeft } = options;
        if (shouldUseInitiativeWorkflow) {
            options.from = sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.INITIATIVE_VIEW;
        }
        if (shouldUseIssuesWorkflow) {
            options.from = sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.ISSUES_VIEW;
        }
        if (shouldUseTimelineWorkflow) {
            options.from = sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.TIMELINE_VIEW;
        }
        if (shouldUseDataDictionaryWorkflow) {
            options.from = sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.DATA_DICTIONARY_VIEW;
        }
        const shouldNotJumpToPeriscopes = shouldUseInitiativeWorkflow ||
            shouldUseIssuesWorkflow ||
            shouldUseTimelineWorkflow ||
            shouldUseDataDictionaryWorkflow;
        if (shouldUseDataDictionaryWorkflow) {
            options.limit = 1;
        }
        else if (shouldNotJumpToPeriscopes) {
            options.limit = 2;
        }
        else {
            options.limit = 3;
        }
        const periscopeIndex = (0, sonar_ts_lib_1.findIndexForPeriscope)(periscopes, id, options.openedFromIndex);
        if (periscopeIndex !== null) {
            logAnalyticEvent((0, sonar_analytics_1.getPeriscopeOpenedEvent)((_a = options.from) !== null && _a !== void 0 ? _a : sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.PERISCOPE_VIEW, options.type));
            addPeriscope(Object.assign(Object.assign({}, options), { periscopeIndex }));
            getInitiativesForObject({ sonarObjectId: options.id });
            if (shouldNotJumpToPeriscopes) {
                return;
            }
            if (periscopeLength === 0 || (openLeft && periscopeIndex === 1)) {
                goToPeriscope(options);
            }
            if (periscopeIndex >= options.limit) {
                goToPeriscope(periscopes[1]);
            }
        }
    }, [
        addPeriscope,
        getInitiativesForObject,
        goToPeriscope,
        logAnalyticEvent,
        periscopeLength,
        periscopes,
        shouldUseDataDictionaryWorkflow,
        shouldUseInitiativeWorkflow,
        shouldUseIssuesWorkflow,
        shouldUseTimelineWorkflow
    ]);
    const startPeriscope = (0, react_1.useCallback)((options) => {
        if (!options.from) {
            options.from = sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.MAIN_MENU;
        }
        openPeriscope(Object.assign(Object.assign({}, options), { openedFromIndex: -1 }));
        if (!shouldUseInitiativeWorkflow) {
            goToPeriscope(options);
        }
    }, [goToPeriscope, shouldUseInitiativeWorkflow, openPeriscope]);
    const closePeriscope = (0, react_1.useCallback)((periscopeIndex) => {
        deletePeriscope({ periscopeIndex });
        const shouldNotReturnToDashboard = shouldUseDataDictionaryWorkflow ||
            shouldUseInitiativeWorkflow ||
            shouldUseIssuesWorkflow ||
            shouldUseTimelineWorkflow;
        if (!shouldNotReturnToDashboard && (periscopeLength === 1 || periscopeIndex === 0)) {
            navigate(sonar_ts_constants_1.FULL_ROUTES.DASHBOARD);
        }
    }, [
        deletePeriscope,
        shouldUseDataDictionaryWorkflow,
        shouldUseInitiativeWorkflow,
        shouldUseIssuesWorkflow,
        shouldUseTimelineWorkflow,
        periscopeLength,
        navigate
    ]);
    return {
        closePeriscope,
        openPeriscope,
        startPeriscope,
        updatePeriscope: updatePeriscopeAction
    };
}
exports.usePeriscopeActions = usePeriscopeActions;
