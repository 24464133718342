"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePulseAppColors = void 0;
const lib_1 = require("@pulse/@arch/lib");
const references_1 = require("@hooks/references");
const react_1 = require("react");
function usePulseAppColors(appIds) {
    const [applicationColors, setApplicationColors] = (0, react_1.useState)({});
    const apps = (0, references_1.useApplicationReferences)();
    (0, react_1.useEffect)(() => {
        if ((appIds === null || appIds === void 0 ? void 0 : appIds.length) && !Object.keys(applicationColors).length) {
            appIds.forEach((id) => {
                var _a;
                const { name } = (_a = apps[id]) !== null && _a !== void 0 ? _a : {};
                (0, lib_1.getAppColor)(name, (color) => {
                    setApplicationColors((colors) => (Object.assign(Object.assign({}, colors), { [id]: color })));
                });
            });
        }
    }, [appIds, applicationColors, apps]);
    return applicationColors;
}
exports.usePulseAppColors = usePulseAppColors;
