"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMaintenanceMode = void 0;
const selectors_1 = require("@lib/selectors");
const useSonarSelector_1 = require("@hooks/useSonarSelector");
const selector = (0, selectors_1.createSonarSelector)((state) => { var _a; return Boolean((_a = state.application.maintenance.read.data) === null || _a === void 0 ? void 0 : _a.locked); });
function useMaintenanceMode() {
    return (0, useSonarSelector_1.useSonarSelector)(selector);
}
exports.useMaintenanceMode = useMaintenanceMode;
