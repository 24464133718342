"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PulseViewContainer = void 0;
require("./PulseViewContainer.scss");
const applications_1 = require("@ui/applications");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const popups_1 = require("@lib/popups");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const DateRangePicker_1 = require("@ui/DateRangePicker");
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const general_1 = require("@ui/general");
const react_router_dom_1 = require("react-router-dom");
const _hooks_1 = require("@hooks");
const PulseViewContainer = ({ children, dateRange, onDateChange, title }) => {
    const { id } = (0, react_router_dom_1.useParams)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const apps = (0, _hooks_1.usePulseApps)(false, true);
    const appsLoaded = (0, _hooks_1.useSonarSelector)(_hooks_1.usePulseApps.selectors.dataExists);
    const onAppSelect = (0, react_1.useCallback)((appId) => {
        navigate(`${sonar_ts_constants_1.FULL_ROUTES.PULSE}${appId ? `/${appId}` : ''}`);
    }, [navigate]);
    (0, react_1.useEffect)(() => {
        if (id && appsLoaded && !apps.some((app) => app.applicationId === Number(id))) {
            navigate(sonar_ts_constants_1.FULL_ROUTES.PULSE);
        }
    });
    return (react_1.default.createElement(general_1.ViewArea, { collapseSidebar: true, direction: 'vertical', fullWidth: true, id: 'pulse-view-container' },
        react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: !appsLoaded },
            react_1.default.createElement("div", { className: 'pulse-view-content' },
                react_1.default.createElement(general_1.SonarCard, { className: 'pulse-view-header', extra: react_1.default.createElement(antd_1.Space, null,
                        react_1.default.createElement(applications_1.AppSelect, { getPopupContainer: popups_1.getClosestCardHeader, includeDetected: true, onChange: onAppSelect, value: id ? Number(id) : undefined }),
                        react_1.default.createElement(DateRangePicker_1.DateRangePicker, { endDate: dateRange.end, onChange: onDateChange, startDate: dateRange.start })), title: title }),
                react_1.default.createElement(antd_1.Row, { gutter: 8 }, children)))));
};
exports.PulseViewContainer = PulseViewContainer;
