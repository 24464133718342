"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBulkSelectWorkflow = exports.getResourceIdsUri = void 0;
const sonar_http_1 = require("@sonar-software/sonar-http");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
function getResourceIdsUri(options) {
    const { childType, parentType, sonarObjectId } = options;
    const tagPrefix = parentType === sonar_ts_types_1.SonarObjectTypeId.SonarTag ? 'tag-' : '';
    return `v3/core/sonar-objects/${sonarObjectId}/${tagPrefix}children?${(0, sonar_http_1.getQueryString)(Object.assign({ childSonarObjectType: childType }, !tagPrefix && { parentSonarObjectType: parentType }))}`;
}
exports.getResourceIdsUri = getResourceIdsUri;
function useBulkSelectWorkflow(options) {
    return {
        resourceIdsUri: getResourceIdsUri(options)
    };
}
exports.useBulkSelectWorkflow = useBulkSelectWorkflow;
