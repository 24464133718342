"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncCollectionStateWithPreload = void 0;
const useAsyncStateWithPreload_1 = require("./useAsyncStateWithPreload");
/**
 * Subscribes to an async state collection. Will load related data via provided action upon mount if data is
 * not available or forced via `forceLoad`.
 * @param stateSelectors A prebuilt {@link AsyncSelectors} object for a results collection state to manage.
 * @param loadAction Action factory for the load request.
 * @param forceLoad Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @param args Default arguments for the action, if any.
 * @returns A {@link UseAsyncCollectionStateWithPreloadResult} instance with the related `data`, if the data
 * `isReceived`, and if the data `isRequested`.
 */
function useAsyncCollectionStateWithPreload(stateSelectors, loadAction, forceLoad, args) {
    const { data, isReceived, isRequested, request } = (0, useAsyncStateWithPreload_1.useAsyncStateWithPreload)(stateSelectors, loadAction, forceLoad, args);
    return {
        data: data === null || data === void 0 ? void 0 : data.results,
        isReceived,
        isRequested,
        request
    };
}
exports.useAsyncCollectionStateWithPreload = useAsyncCollectionStateWithPreload;
