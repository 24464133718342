"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentUserEmail = void 0;
const notifications_1 = require("@ui/notifications");
const selectors_1 = require("@lib/selectors");
const CurrentPasswordModal_1 = require("@ui/CurrentPasswordModal");
const form_items_1 = require("@ui/form-items");
const sonar_http_1 = require("@sonar-software/sonar-http");
const sonar_security_1 = require("@sonar-software/sonar-security");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const patchChangeEmailSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.authentication.changeEmail.patch);
const buttonText = `${sonar_ts_constants_1.COPY_SPECIFIC.UPDATE} ${sonar_ts_constants_1.COPY_SPECIFIC.EMAIL}`;
const CurrentUserEmail = () => {
    const emailFormRef = (0, react_1.useRef)(null);
    const [showModal, setShowModal] = (0, react_1.useState)(false);
    const { email: userEmail, id: userId } = (0, _hooks_1.useCurrentUser)();
    const email = userEmail !== null && userEmail !== void 0 ? userEmail : '';
    const isRequested = (0, _hooks_1.useSonarSelector)(patchChangeEmailSelectors.isRequested);
    const isSuccessful = (0, _hooks_1.useSonarSelector)(patchChangeEmailSelectors.isReceived);
    const isErrored = (0, _hooks_1.useSonarSelector)(patchChangeEmailSelectors.isErrored);
    const isFailed = (0, _hooks_1.useSonarSelector)(patchChangeEmailSelectors.isFailed);
    const requestStatusCode = (0, _hooks_1.useSonarSelector)(patchChangeEmailSelectors.statusCode);
    const requestEmailUpdate = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestPatchSecurityAuthenticationChangeEmail);
    const resetEmailUpdateRequest = (0, _hooks_1.useAction)(sonar_security_1.securityActions.resetPatchSecurityAuthenticationChangeEmail);
    const handleOnEmailFinish = (0, react_1.useCallback)(() => {
        setShowModal(true);
    }, []);
    const handleOnCancelClick = (0, react_1.useCallback)(() => {
        setShowModal(false);
    }, []);
    const failureMessage = requestStatusCode === sonar_http_1.ErrorOrFailStatusCode.Conflict ?
        sonar_ts_constants_1.COPY_SPECIFIC.EMAIL_IN_USE :
        sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_PATCH_USER_CHANGE_EMAIL;
    const handleOnOkClick = (0, react_1.useCallback)((currentPassword) => {
        var _a;
        const { current } = emailFormRef;
        if (current && userId) {
            const newEmail = (_a = current.getFieldValue('email')) !== null && _a !== void 0 ? _a : '';
            requestEmailUpdate({
                currentPassword,
                email: newEmail.trim(),
                id: userId
            });
        }
    }, [requestEmailUpdate, userId]);
    (0, react_1.useEffect)(() => {
        if (isSuccessful) {
            setShowModal(false);
            resetEmailUpdateRequest();
        }
    }, [isSuccessful, resetEmailUpdateRequest]);
    return (react_1.default.createElement("div", { id: 'email' },
        react_1.default.createElement(antd_1.Form, { initialValues: {
                currentPassword: null,
                email
            }, layout: 'vertical', name: 'email', onFinish: handleOnEmailFinish, ref: emailFormRef, requiredMark: false },
            react_1.default.createElement(form_items_1.Email, { disabled: isRequested, id: 'user-email', label: sonar_ts_constants_1.COPY_SPECIFIC.EMAIL }),
            react_1.default.createElement(antd_1.Button, { disabled: isRequested, htmlType: 'submit', id: 'user-email-submit-button', type: 'primary' }, buttonText)),
        react_1.default.createElement(CurrentPasswordModal_1.CurrentPasswordModal, { error: isErrored, errorMessage: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_PATCH_USER_CHANGE_EMAIL, failed: isFailed, failedMessage: failureMessage, onCancel: handleOnCancelClick, onOk: handleOnOkClick, open: showModal }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_PATCH_USER_CHANGE_EMAIL, stateSelector: patchChangeEmailSelectors.state })));
};
exports.CurrentUserEmail = CurrentUserEmail;
