"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitiativeTasks = void 0;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useInitiativeReference_1 = require("./useInitiativeReference");
const useOnMount_1 = require("../useOnMount");
const usePrevious_1 = require("../usePrevious");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = require("react");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.tasks.get);
function useInitiativeTasks(initiativeId, refresh = false) {
    const isRequested = (0, useSonarSelector_1.useSonarSelector)(selectors.isRequested);
    const previousInitiativeId = (0, usePrevious_1.usePrevious)(initiativeId);
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestGetCoreInitiativesTasks);
    const loadTasks = (0, react_1.useCallback)(() => {
        if (initiativeId) {
            request({ initiativeId });
        }
    }, [initiativeId, request]);
    (0, useOnMount_1.useOnMount)(() => {
        if (refresh) {
            loadTasks();
        }
    });
    (0, react_1.useEffect)(() => {
        if (previousInitiativeId && previousInitiativeId !== initiativeId) {
            loadTasks();
        }
    }, [initiativeId, loadTasks, previousInitiativeId]);
    const { tasks } = (0, useInitiativeReference_1.useInitiativeReference)(initiativeId);
    return {
        isRequested,
        refresh: loadTasks,
        tasks
    };
}
exports.useInitiativeTasks = useInitiativeTasks;
