"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAuth = exports.clearAuth = exports.AuthActionType = void 0;
var AuthActionType;
(function (AuthActionType) {
    AuthActionType["Clear"] = "clearAuth";
    AuthActionType["Update"] = "updateAuth";
})(AuthActionType || (exports.AuthActionType = AuthActionType = {}));
function clearAuth() {
    return { type: AuthActionType.Clear };
}
exports.clearAuth = clearAuth;
function updateAuth(token) {
    return {
        data: token,
        type: AuthActionType.Update
    };
}
exports.updateAuth = updateAuth;
