"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddApp = exports.patchAppSelectors = exports.postAppSelectors = void 0;
require("./AddApp.scss");
const _1 = require(".");
const xuder_1 = require("@sonar-software/xuder");
const selectors_1 = require("@lib/selectors");
const sonar_http_1 = require("@sonar-software/sonar-http");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const monitors_1 = require("../monitors");
const general_1 = require("@ui/general");
const app_form_1 = require("../app-form");
const antd_1 = require("antd");
const validation_1 = require("../@arch/validation");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const monitor_form_1 = require("../monitor-form");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const react_router_dom_1 = require("react-router-dom");
exports.postAppSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.applications.post);
exports.patchAppSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.applications.byId.patch);
const pulseMonitorSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.monitors.get);
const defaultMonitors = monitors_1.monitorConfigs.filter((x) => x.intrinsic)
    .map((item) => ({ monitorTypeId: item.type }));
const AddApp = () => {
    var _a;
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [searchParams] = (0, react_router_dom_1.useSearchParams)();
    const applicationId = Number(searchParams.get('applicationId'));
    const apps = (0, _hooks_1.usePulseApps)(false, true);
    const app = apps.find((x) => x.applicationId === applicationId);
    const { errorNotification } = (0, _hooks_1.useAppNotifications)();
    const { data: monitorData, isRequested, isReceived } = (0, _hooks_1.useAsyncState)(pulseMonitorSelectors);
    const usedComboRef = (0, react_1.useRef)({});
    const monitorFormRef = (0, react_1.useRef)(null);
    const loadPulseAppMonitors = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCorePulseApplicationMonitors);
    const postApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCorePulseApplications);
    const resetPostApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPostCorePulseApplications);
    const patchApplication = (0, _hooks_1.useUpdatePulseApp)();
    const { form: appSettingsForm } = (0, _hooks_1.useForm)({
        required: ['name']
    });
    (0, _hooks_1.useOnMount)(() => {
        if (applicationId) {
            loadPulseAppMonitors({ applicationId });
        }
    });
    (0, react_1.useEffect)(() => {
        if (applicationId && (app === null || app === void 0 ? void 0 : app.name)) {
            appSettingsForm.setFieldsValue({ name: app.name });
            appSettingsForm.validate();
        }
    }, [app, appSettingsForm, applicationId]);
    const handleReturn = (0, react_1.useCallback)(() => {
        navigate(sonar_ts_constants_1.FULL_ROUTES.SETTINGS_PULSE);
        appSettingsForm.reset();
    }, [appSettingsForm, navigate]);
    const onConflict = (0, react_1.useCallback)(() => {
        var _a, _b;
        const name = (_a = appSettingsForm.getFieldValue('name')) !== null && _a !== void 0 ? _a : '';
        const label = (_b = appSettingsForm.getFieldValue('label')) !== null && _b !== void 0 ? _b : '';
        usedComboRef.current = {
            label,
            name
        };
        appSettingsForm.validateFields(['name', 'label']);
    }, [appSettingsForm]);
    const processPost = (0, _hooks_1.useAsyncProcessing)({
        resetAction: resetPostApplication,
        stateSelector: exports.postAppSelectors.state,
        statusCodeOptions: {
            [sonar_http_1.ErrorOrFailStatusCode.Conflict]: onConflict
        },
        statusOptions: {
            [xuder_1.AsyncStateStatus.Received]: handleReturn,
            [xuder_1.AsyncStateStatus.Errored]: () => errorNotification(sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT),
            [xuder_1.AsyncStateStatus.Failed]: () => errorNotification(sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT)
        }
    });
    const processPatch = (0, _hooks_1.useAsyncProcessing)({
        resetAction: patchApplication.reset,
        stateSelector: exports.patchAppSelectors.state,
        statusCodeOptions: {
            [sonar_http_1.ErrorOrFailStatusCode.Conflict]: onConflict
        },
        statusOptions: {
            [xuder_1.AsyncStateStatus.Received]: handleReturn,
            [xuder_1.AsyncStateStatus.Errored]: () => errorNotification(sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT),
            [xuder_1.AsyncStateStatus.Failed]: () => errorNotification(sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT)
        }
    });
    const handleOnValuesChange = (0, react_1.useCallback)((changedValues) => {
        appSettingsForm.validate();
        if (changedValues.name || changedValues.label) {
            const [changedField] = Object.keys(changedValues);
            const [name, label] = appSettingsForm.getFieldsError(['name', 'label']);
            const hasErrors = name.errors.length || label.errors.length;
            appSettingsForm.validateFields(hasErrors ?
                [
                    ...name.errors.length ? ['name'] : [],
                    ...label.errors.length ? ['label'] : []
                ] :
                [changedField]);
        }
    }, [appSettingsForm]);
    const handleOnFinish = (0, react_1.useCallback)(() => {
        var _a, _b, _c, _d;
        appSettingsForm.validate();
        (_a = monitorFormRef.current) === null || _a === void 0 ? void 0 : _a.validate();
        const { name, user, label, description, owner, stakeholderIds, group, activityPeriod } = appSettingsForm.getFieldsValue();
        const data = {
            activityPeriod,
            description,
            label,
            name,
            ownerId: owner,
            rawSalesforceId: user,
            stakeholderGroupId: group,
            stakeholderIds
        };
        if (applicationId) {
            processPatch.watchNextCall();
            patchApplication.request(Object.assign(Object.assign({}, data), { applicationId, status: sonar_core_1.PulseApplicationStatus.Added }));
            (_b = monitorFormRef.current) === null || _b === void 0 ? void 0 : _b.saveAllMonitors();
        }
        else {
            processPost.watchNextCall();
            const monitors = (_d = (_c = monitorFormRef.current) === null || _c === void 0 ? void 0 : _c.getMonitors()) !== null && _d !== void 0 ? _d : [];
            postApplication(Object.assign(Object.assign({}, data), { monitors }));
        }
    }, [
        appSettingsForm,
        monitorFormRef,
        processPatch,
        processPost,
        applicationId,
        patchApplication,
        postApplication
    ]);
    const validateNameLabelCombination = (0, react_1.useCallback)(() => {
        var _a, _b;
        const currentName = (_a = appSettingsForm.getFieldValue('name')) !== null && _a !== void 0 ? _a : '';
        const currentLabel = (_b = appSettingsForm.getFieldValue('label')) !== null && _b !== void 0 ? _b : '';
        const { name, label } = usedComboRef.current;
        if (name === currentName && label === currentLabel) {
            return Promise.reject(currentLabel ? validation_1.CONFLICT_MESSAGE : validation_1.DUPLICATE_MESSAGE);
        }
        return Promise.resolve();
    }, [appSettingsForm]);
    return (react_1.default.createElement(general_1.SonarCard, { id: 'add-app', title: sonar_ts_constants_1.COPY_SPECIFIC.ADD_A_NEW_APP },
        react_1.default.createElement(antd_1.Row, null,
            react_1.default.createElement(app_form_1.AddAppSettings, { applicationName: app === null || app === void 0 ? void 0 : app.name, form: appSettingsForm, onFinish: handleOnFinish, onValuesChange: handleOnValuesChange, validator: validateNameLabelCombination }),
            react_1.default.createElement(_1.AddAppSection, { className: 'add-app-monitor-section', title: sonar_ts_constants_1.SETTINGS_PULSE_COPY.MONITORS },
                react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: Boolean(applicationId) && (isRequested || !isReceived) },
                    react_1.default.createElement(monitor_form_1.MonitorForm, { applicationId: applicationId, isFormList: true, monitors: monitorData && (monitorData === null || monitorData === void 0 ? void 0 : monitorData.length) > 0 ? monitorData : defaultMonitors, ref: monitorFormRef })))),
        react_1.default.createElement(antd_1.Row, { className: 'add-app-buttons' },
            react_1.default.createElement(antd_1.Button, { onClick: handleReturn }, sonar_ts_constants_1.COPY_SPECIFIC.CANCEL),
            react_1.default.createElement(antd_1.Button, { disabled: !appSettingsForm.isValid ||
                    !((_a = monitorFormRef.current) === null || _a === void 0 ? void 0 : _a.isValid), onClick: appSettingsForm.submit, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.ADD_NEW_APP))));
};
exports.AddApp = AddApp;
