"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLogout = void 0;
const _app_1 = require("@app");
const useAction_1 = require("./useAction");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
function useLogout() {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const hardResetApp = (0, useAction_1.useAction)(_app_1.appActions.hardResetApplication);
    const logout = (0, react_1.useCallback)(() => {
        hardResetApp();
        navigate('/', { replace: true });
    }, [hardResetApp, navigate]);
    return logout;
}
exports.useLogout = useLogout;
