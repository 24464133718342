"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSonarObjectReference = void 0;
const selectors_1 = require("@lib/selectors");
const useSonarSelector_1 = require("../useSonarSelector");
const mapSelector = (0, selectors_1.createSonarSelector)((state) => state.core.sonarObjects.byIdMap);
const emptyMap = {};
const empty = {
    context: {
        definition: '',
        notes: ''
    },
    ingestedBy: [],
    initiativeIds: [],
    linkedIntegrations: [],
    populatedBy: [],
    relatedCounts: {
        pardotRelatedCounts: {},
        salesforceRelatedCounts: {}
    },
    salesforce: {},
    stakeholders: [],
    tagIds: [],
    users: []
};
/**
 * Subscribes to the shared state for a particular sonar object.
 * @param sonarObjectId The id of the sonar object to subscribe to.
 * @returns A {@link SonarObjectReference} instance for the provided id.
 */
function useSonarObjectReference(sonarObjectId) {
    return (0, useSonarSelector_1.useSonarSelector)((state) => { var _a, _b; return (_b = ((_a = mapSelector(state).data) !== null && _a !== void 0 ? _a : emptyMap)[sonarObjectId]) !== null && _b !== void 0 ? _b : empty; });
}
exports.useSonarObjectReference = useSonarObjectReference;
