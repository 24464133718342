"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAsyncSelectors = exports.createIsErroredOrFailedSelector = exports.createIsFailedSelector = exports.createIsErroredSelector = exports.createIsReceivedSelector = exports.createIsRequestedSelector = exports.createIsIdleSelector = exports.createCollectionSelector = exports.createStatusCodeSelector = exports.createStatusSelector = exports.createDataExistsSelector = exports.createDataSelector = exports.asyncStateStatusCodeSelector = exports.asyncStateStatusSelector = exports.createSonarSelector = void 0;
const xuder_1 = require("@sonar-software/xuder");
function createSonarSelector(selector) {
    return selector;
}
exports.createSonarSelector = createSonarSelector;
function asyncStateStatusSelector(selector) {
    return (state) => selector(state).actionStatus;
}
exports.asyncStateStatusSelector = asyncStateStatusSelector;
function asyncStateStatusCodeSelector(selector) {
    return (state) => selector(state).statusCode;
}
exports.asyncStateStatusCodeSelector = asyncStateStatusCodeSelector;
function createDataSelector(selector) {
    return createSonarSelector((state) => selector(state).data);
}
exports.createDataSelector = createDataSelector;
function createDataExistsSelector(selector) {
    return createSonarSelector((state) => Boolean(selector(state).data));
}
exports.createDataExistsSelector = createDataExistsSelector;
function createStatusSelector(selector) {
    return createSonarSelector(asyncStateStatusSelector(selector));
}
exports.createStatusSelector = createStatusSelector;
function createStatusCodeSelector(selector) {
    return createSonarSelector(asyncStateStatusCodeSelector(selector));
}
exports.createStatusCodeSelector = createStatusCodeSelector;
function createCollectionSelector(selector) {
    return createSonarSelector((state) => { var _a; return (_a = selector(state)) === null || _a === void 0 ? void 0 : _a.results; });
}
exports.createCollectionSelector = createCollectionSelector;
function createIsIdleSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isIdle)(selector(state)));
}
exports.createIsIdleSelector = createIsIdleSelector;
function createIsRequestedSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isRequested)(selector(state)));
}
exports.createIsRequestedSelector = createIsRequestedSelector;
function createIsReceivedSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isReceived)(selector(state)));
}
exports.createIsReceivedSelector = createIsReceivedSelector;
function createIsErroredSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isErrored)(selector(state)));
}
exports.createIsErroredSelector = createIsErroredSelector;
function createIsFailedSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isFailed)(selector(state)));
}
exports.createIsFailedSelector = createIsFailedSelector;
function createIsErroredOrFailedSelector(selector) {
    const erroredSelector = createIsErroredSelector(selector);
    const failedSelector = createIsFailedSelector(selector);
    return (state) => erroredSelector(state) || failedSelector(state);
}
exports.createIsErroredOrFailedSelector = createIsErroredOrFailedSelector;
function createAsyncSelectors(selector) {
    return {
        data: createDataSelector(selector),
        dataExists: createDataExistsSelector(selector),
        isErrored: createIsErroredSelector(selector),
        isFailed: createIsFailedSelector(selector),
        isIdle: createIsIdleSelector(selector),
        isReceived: createIsReceivedSelector(selector),
        isRequested: createIsRequestedSelector(selector),
        state: selector,
        status: createStatusSelector(selector),
        statusCode: createStatusCodeSelector(selector)
    };
}
exports.createAsyncSelectors = createAsyncSelectors;
