"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePulseApps = void 0;
const selectors_1 = require("@lib/selectors");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.applications.all.get);
const empty = [];
let isRequested = false;
/**
 * Subscribes to and returns the collection of pulse applications. Will load related data upon mount if data is not
 * available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Array of {@link PulseApplication}.
 */
function usePulseApps(refresh = false, includeDetected = false, includeDeleted = false) {
    const { data } = (0, _hooks_1.useAsyncState)(selectors);
    const isEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.Pulse);
    const request = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCorePulseApplicationsAll);
    (0, _hooks_1.useOnMount)(() => {
        if (isEnabled && (refresh || !isRequested)) {
            request();
            isRequested = true;
        }
    });
    if (!isEnabled) {
        return empty;
    }
    const results = (data === null || data === void 0 ? void 0 : data.results) || empty;
    if (!includeDetected || !includeDeleted) {
        return results.filter((app) => (app.status !== sonar_core_1.PulseApplicationStatus.Detected && app.status !== sonar_core_1.PulseApplicationStatus.Deleted) ||
            (app.status === sonar_core_1.PulseApplicationStatus.Deleted && includeDeleted) ||
            (app.status === sonar_core_1.PulseApplicationStatus.Detected && includeDetected));
    }
    return results;
}
exports.usePulseApps = usePulseApps;
usePulseApps.selectors = selectors;
