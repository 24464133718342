"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFollowingIdReference = void 0;
const selectors_1 = require("@lib/selectors");
const _hooks_1 = require("@hooks");
const selector = (0, selectors_1.createSonarSelector)((state) => state.core.followers.byId.read);
function useFollowingIdReference() {
    const { data } = (0, _hooks_1.useSonarSelector)(selector);
    return data;
}
exports.useFollowingIdReference = useFollowingIdReference;
