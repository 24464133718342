"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSuggestionEditor = void 0;
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const useAmplitude_1 = require("./useAmplitude");
const useApiResource_1 = require("./useApiResource");
const app_1 = require("./app");
const useAuthToken_1 = require("./useAuthToken");
const useFeatureFlags_1 = require("./useFeatureFlags");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_http_1 = require("@sonar-software/sonar-http");
const Editor_1 = require("@ui/Editor");
const react_1 = require("react");
function useSuggestionEditor(options) {
    const { autodocType, isReadOnly, richText, sonarObjectId, value } = options;
    const [accepted, setAccepted] = (0, react_1.useState)(false);
    const [hasError, setError] = (0, react_1.useState)(false);
    const { isReadOnly: isUserReadOnly } = (0, useAuthToken_1.useAuthToken)();
    const { errorNotification } = (0, app_1.useAppNotifications)();
    const enabled = (0, useFeatureFlags_1.useFeatureFlag)(sonar_core_1.FeatureFlag.Autodoc);
    const log = (0, useAmplitude_1.useAmplitude)();
    const isEmpty = richText ? !(0, Editor_1.hasContent)((0, Editor_1.getInitialEditorState)(value || '')) : !value;
    const { reset: apiReset, request: apiRequest, resource, response, loading } = (0, useApiResource_1.useApiResource)(`v3/core/sonar-objects/${sonarObjectId}/suggestions/${autodocType}`, null, true);
    const suggestionValue = (0, react_1.useMemo)(() => {
        if (!(resource === null || resource === void 0 ? void 0 : resource.text)) {
            return undefined;
        }
        let cleanValue = resource.text.trim();
        if (cleanValue.startsWith('"') && cleanValue.endsWith('"')) {
            cleanValue = cleanValue.slice(1, -1);
        }
        return cleanValue;
    }, [resource === null || resource === void 0 ? void 0 : resource.text]);
    const request = (0, react_1.useCallback)(() => {
        log(sonar_analytics_1.AMPLITUDE_EVENT.SUGGESTION_GENERATED, { autodocType });
        apiRequest();
    }, [apiRequest, autodocType, log]);
    const reset = (0, react_1.useCallback)(() => {
        setAccepted(false);
        apiReset();
    }, [apiReset, setAccepted]);
    const accept = (0, react_1.useCallback)(() => {
        if (suggestionValue && !accepted) {
            log(sonar_analytics_1.AMPLITUDE_EVENT.SUGGESTION_ACCEPTED, { autodocType });
        }
        setAccepted(true);
    }, [accepted, autodocType, log, suggestionValue]);
    (0, react_1.useEffect)(() => {
        if (suggestionValue && !isEmpty) {
            reset();
        }
    }, [reset, suggestionValue, isEmpty]);
    (0, react_1.useEffect)(() => {
        if ((response === null || response === void 0 ? void 0 : response.status) && (response === null || response === void 0 ? void 0 : response.status) !== sonar_http_1.SuccessStatusCode.Ok) {
            if ((response === null || response === void 0 ? void 0 : response.status) === sonar_http_1.ErrorOrFailStatusCode.BadGateway) {
                errorNotification(sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_GET_AUTODOC_SUGGESTION_FAILED(sonar_core_1.AUTODOC_TYPE_LABELS[autodocType].toLocaleLowerCase()), {
                    message: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_GET_AUTODOC_SUGGESTION_TITLE
                });
            }
            else {
                errorNotification(sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_GET_AUTODOC_SUGGESTION, {
                    message: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_GET_AUTODOC_SUGGESTION_TITLE
                });
            }
            setError(true);
            setTimeout(() => setError(false), 5000);
            reset();
        }
    }, [reset, response === null || response === void 0 ? void 0 : response.status, autodocType, errorNotification]);
    const isEditing = !enabled || isReadOnly || isUserReadOnly || !isEmpty || accepted;
    const editorValue = suggestionValue && isEmpty ? suggestionValue : value !== null && value !== void 0 ? value : '';
    return {
        accept,
        accepted,
        editorValue,
        hasError,
        isEditing,
        loading,
        request,
        reset,
        value: suggestionValue
    };
}
exports.useSuggestionEditor = useSuggestionEditor;
