"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDownload = void 0;
const xuder_1 = require("@sonar-software/xuder");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const useAction_1 = require("./useAction");
const app_1 = require("./app");
const react_1 = require("react");
function downloadFile(response) {
    if (response.data) {
        const blobUrl = URL.createObjectURL(response.data);
        const contentDisposition = response.headers.get('content-disposition');
        const filename = (0, sonar_ts_lib_1.getFilenameFromContentDisposition)(contentDisposition !== null && contentDisposition !== void 0 ? contentDisposition : '');
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', filename);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobUrl);
        }, 100);
    }
}
function useDownload() {
    const logError = (0, useAction_1.useAction)(xuder_1.error);
    const { errorNotification } = (0, app_1.useAppNotifications)();
    const download = (0, react_1.useCallback)((options) => __awaiter(this, void 0, void 0, function* () {
        const { errorMessage, onFinish, request } = options;
        try {
            const httpResponse = yield request();
            if (httpResponse.data) {
                downloadFile(httpResponse);
            }
            else {
                throw new Error('Unable to download file.');
            }
        }
        catch (ex) {
            logError(ex);
            errorNotification(errorMessage);
        }
        finally {
            onFinish === null || onFinish === void 0 ? void 0 : onFinish();
        }
    }), [errorNotification, logError]);
    return download;
}
exports.useDownload = useDownload;
