"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScroll = void 0;
const react_1 = require("react");
const debounceScroll = (onScroll) => {
    let frame;
    return (e) => {
        if (frame) {
            cancelAnimationFrame(frame);
        }
        frame = requestAnimationFrame(() => {
            onScroll(e);
        });
    };
};
function useScroll() {
    const [scrollTop, setScrollTop] = (0, react_1.useState)(0);
    const onScroll = (0, react_1.useCallback)((e) => {
        setScrollTop(e.target.scrollTop);
    }, []);
    return {
        scrollTop,
        watchScroll: debounceScroll(onScroll)
    };
}
exports.useScroll = useScroll;
