"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDataDictionaryReset = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const useAction_1 = require("../useAction");
const react_1 = require("react");
function useDataDictionaryReset() {
    const resetDataDictionary = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetGetCoreDataDictionary);
    const resetDataDictionarySearch = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetGetCoreDataDictionarySearch);
    const resetAll = (0, react_1.useCallback)(() => {
        resetDataDictionary();
        resetDataDictionarySearch();
    }, [resetDataDictionary, resetDataDictionarySearch]);
    return {
        resetAll,
        resetData: resetDataDictionary,
        resetSearch: resetDataDictionarySearch
    };
}
exports.useDataDictionaryReset = useDataDictionaryReset;
