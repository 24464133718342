"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateSonarObjectReference = void 0;
const _hooks_1 = require("@hooks");
const react_1 = require("react");
const sonar_core_1 = require("@sonar-software/sonar-core");
function useUpdateSonarObjectReference() {
    const updateAction = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateSonarObjectReferences);
    const updateAllAction = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateAllSonarObjectReferences);
    const update = (0, react_1.useCallback)((request) => {
        updateAction(Array.isArray(request) ? request : [request]);
    }, [updateAction]);
    return {
        update,
        updateAll: updateAllAction
    };
}
exports.useUpdateSonarObjectReference = useUpdateSonarObjectReference;
