"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLinkTag = void 0;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.tags.link.post);
/**
 * Subscribes to the link tag processing. Provides actions to request and reset, and flags for status
 * @returns A {@link UseLinkTagResult} with processing flags and actions.
 */
function useLinkTag() {
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreTagsLink);
    const reset = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetPostCoreTagsLink);
    const isProcessing = (0, useSonarSelector_1.useSonarSelector)(selectors.isRequested);
    const isSuccessful = (0, useSonarSelector_1.useSonarSelector)(selectors.isReceived);
    return {
        isProcessing,
        isSuccessful,
        request,
        reset
    };
}
exports.useLinkTag = useLinkTag;
useLinkTag.selectors = selectors;
