"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.softResetApplication = exports.resetApplicationComplete = exports.resetApplication = exports.hardResetApplication = exports.ApplicationActionType = void 0;
var ApplicationActionType;
(function (ApplicationActionType) {
    ApplicationActionType["HardReset"] = "hardReset";
    ApplicationActionType["Reset"] = "reset";
    ApplicationActionType["ResetComplete"] = "resetComplete";
    ApplicationActionType["SoftReset"] = "softReset";
})(ApplicationActionType || (exports.ApplicationActionType = ApplicationActionType = {}));
function hardResetApplication() {
    return { type: ApplicationActionType.HardReset };
}
exports.hardResetApplication = hardResetApplication;
function resetApplication() {
    return { type: ApplicationActionType.Reset };
}
exports.resetApplication = resetApplication;
function resetApplicationComplete() {
    return { type: ApplicationActionType.ResetComplete };
}
exports.resetApplicationComplete = resetApplicationComplete;
function softResetApplication() {
    return { type: ApplicationActionType.SoftReset };
}
exports.softResetApplication = softResetApplication;
