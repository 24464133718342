"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSensitivitySelector = void 0;
const DataSensitivityLevelTag_1 = require("./DataSensitivityLevelTag");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const SonarSelector_1 = require("../SonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
function updateDataSensitivity(sonarObjectId, classificationId) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const result = yield (0, sonar_core_1.patchSalesforceSonarObject)({
            classificationId,
            sonarObjectId
        });
        return (_a = result.data) !== null && _a !== void 0 ? _a : _hooks_1.genericProcessingFailure;
    });
}
const options = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.DataSensitivityLevel).map((level) => ({
    label: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevel: level }),
    optionLabel: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevel: level }),
    searchValue: sonar_core_1.DATA_SENSITIVITY_LABELS[level],
    value: level
}));
const DataSensitivitySelector = (_a) => {
    var _b, _c;
    var { isSpreadsheet, sonarObjectId } = _a, rest = __rest(_a, ["isSpreadsheet", "sonarObjectId"]);
    const { dataSensitivityLevel } = (_c = (_b = (0, _hooks_1.useSonarObjectReference)(sonarObjectId)) === null || _b === void 0 ? void 0 : _b.salesforce) !== null && _c !== void 0 ? _c : {};
    const { update } = (0, _hooks_1.useUpdateSonarObjectReference)();
    const { start, state, stop } = (0, _hooks_1.useProcessing)();
    const [value, setValue] = (0, react_1.useState)(dataSensitivityLevel);
    (0, react_1.useEffect)(() => {
        if (value === undefined && value !== dataSensitivityLevel) {
            setValue(dataSensitivityLevel);
        }
    }, [dataSensitivityLevel, value]);
    const handleChange = (0, react_1.useCallback)((change) => __awaiter(void 0, void 0, void 0, function* () {
        const newValue = change !== null && change !== void 0 ? change : sonar_core_1.DataSensitivityLevel.None;
        if (newValue === dataSensitivityLevel) {
            return;
        }
        const fallback = value;
        setValue(newValue);
        start();
        const result = yield updateDataSensitivity(sonarObjectId, newValue);
        if (result.success) {
            update({
                salesforce: { dataSensitivityLevel: newValue },
                sonarObjectId
            });
        }
        else {
            setValue(fallback);
        }
        stop(result);
    }), [dataSensitivityLevel, sonarObjectId, start, stop, update, value]);
    return (react_1.default.createElement(SonarSelector_1.SonarSelector, Object.assign({}, rest, { allowClear: dataSensitivityLevel !== sonar_core_1.DataSensitivityLevel.None, boxless: !isSpreadsheet, className: 'salesforce-data-sensitivity-selector', defaultValue: dataSensitivityLevel || sonar_core_1.DataSensitivityLevel.None, isSalesforce: true, isSpreadsheet: isSpreadsheet, onChange: handleChange, options: options, placeholder: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevel: sonar_core_1.DataSensitivityLevel.None }), readOnlyDisplay: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevel: dataSensitivityLevel || sonar_core_1.DataSensitivityLevel.None }), showArrow: true, showSearch: false, state: state, value: state.isProcessing ? value : dataSensitivityLevel || sonar_core_1.DataSensitivityLevel.None })));
};
exports.DataSensitivitySelector = DataSensitivitySelector;
