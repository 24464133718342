"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removePeriscope = void 0;
const action_types_1 = require("./action_types");
function removePeriscope(removePeriscopeSync) {
    return {
        data: removePeriscopeSync,
        type: action_types_1.REMOVE_ACTION_TYPES.REMOVE_PERISCOPE
    };
}
exports.removePeriscope = removePeriscope;
