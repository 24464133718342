"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataDictionaryBulkEditModal = void 0;
require("./DataDictionaryBulkEditModal.scss");
const BulkEditModalSection_1 = require("./BulkEditModalSection");
const BulkFollowCheckbox_1 = require("./BulkFollowCheckbox");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const features_1 = require("@ui/features");
const FieldUsageBulkSelector_1 = require("./FieldUsageBulkSelector");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const InitiativeSelector_1 = require("./InitiativeSelector");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const StakeholderSelector_1 = require("@ui/StakeholderSelector");
const TagSelector_1 = require("./TagSelector");
const TaskSelector_1 = require("./TaskSelector");
const UserSelector_1 = require("@ui/UserSelector");
const useUpdateFollowing_1 = require("@hooks/followers/useUpdateFollowing");
const tags_1 = require("@ui/tags");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_if_1 = require("react-if");
const modals_1 = require("@ui/modals");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function bulkEditFieldProperties(sonarObjectIds, complianceCategoryId, dataSensitivityLevel, fieldUsage) {
    const request = { sonarObjectIds };
    if (complianceCategoryId !== undefined) {
        request.complianceCategoryId = complianceCategoryId;
    }
    if (dataSensitivityLevel !== undefined) {
        request.classificationId = dataSensitivityLevel;
    }
    if (fieldUsage !== undefined) {
        request.fieldUsage = fieldUsage;
    }
    (0, sonar_core_1.patchSalesforceSonarObjectsBulk)(request);
}
const DataDictionaryBulkEditModal = ({ onCancel, onOk, open, popoverTarget, popoverPosition = 'bottomLeft', selected, sonarObjectType }) => {
    const [ownerId, setOwnerId] = (0, react_1.useState)();
    const [stakeholderIds, setStakeholderIds] = (0, react_1.useState)();
    const [following, setFollowing] = (0, react_1.useState)();
    const [complianceCategory, setComplianceCategory] = (0, react_1.useState)();
    const [dataSensitivity, setDataSensitivity] = (0, react_1.useState)();
    const [fieldUsage, setFieldUsage] = (0, react_1.useState)();
    const [tagId, setTagId] = (0, react_1.useState)();
    const [initiativeOption, setInitiativeOption] = (0, react_1.useState)();
    const [taskOption, setTaskOption] = (0, react_1.useState)();
    const [shouldAddToTask, setShouldAddToTask] = (0, _hooks_1.useBoolean)(false);
    const sonarObjectIds = (0, react_1.useMemo)(() => selected.map((id) => Number(id)), [selected]);
    const { getGlobalSelection } = (0, _hooks_1.useGlobalSelect)();
    const { request: linkTags } = (0, _hooks_1.useLinkTag)();
    const { request: addToInitiative } = (0, _hooks_1.useAddToInitiative)();
    const { request: addToTask } = (0, _hooks_1.useLinkInitiativeTask)();
    const updateBusinessContextOwnerBulk = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPutCoreSonarObjectsUsersBulk);
    const updateBusinessContextStakeholdersBulk = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPutCoreSonarObjectsStakeholdersBulk);
    const { follow, unfollow } = (0, useUpdateFollowing_1.useUpdateFollowing)(sonarObjectIds !== null && sonarObjectIds !== void 0 ? sonarObjectIds : []);
    const followersEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.Followers);
    const typeLabel = sonarObjectType ? (0, sonar_ts_lib_1.getSonarObjectTypeLabelPlural)(sonarObjectType) : sonar_ts_constants_1.COPY_SPECIFIC.ITEMS;
    const selectedFields = getGlobalSelection({ types: [sonar_ts_types_1.SonarObjectType.SalesforceField] });
    const hasFields = sonarObjectType === sonar_ts_types_1.SonarObjectType.SalesforceField || selectedFields.length > 0;
    const title = `${sonar_ts_constants_1.COPY_SPECIFIC.EDITING} ${selected.length} ${typeLabel}`;
    const okText = `${sonar_ts_constants_1.COPY_SPECIFIC.UPDATE} ${selected.length} ${typeLabel}`;
    const okEnabled = ownerId ||
        stakeholderIds ||
        following !== undefined ||
        complianceCategory !== undefined ||
        dataSensitivity ||
        fieldUsage ||
        tagId ||
        initiativeOption;
    const reset = () => {
        setOwnerId(undefined);
        setStakeholderIds(undefined);
        setFollowing(undefined);
        setComplianceCategory(undefined);
        setDataSensitivity(undefined);
        setFieldUsage(undefined);
        setTagId(undefined);
        setInitiativeOption(undefined);
        setTaskOption(undefined);
    };
    (0, react_1.useEffect)(() => {
        if (!initiativeOption) {
            setShouldAddToTask.off();
            setTaskOption(undefined);
        }
    }, [initiativeOption, setShouldAddToTask]);
    const editOwner = () => {
        updateBusinessContextOwnerBulk({
            sonarObjectIds,
            users: [
                {
                    responsibilityType: sonar_core_1.ResponsibilityType.Owner,
                    userId: Number(ownerId)
                }
            ]
        });
    };
    const editStakeholders = () => {
        const id = !Array.isArray(stakeholderIds) && stakeholderIds ? [stakeholderIds] : [];
        const ids = Array.isArray(stakeholderIds) ? stakeholderIds : id;
        updateBusinessContextStakeholdersBulk({
            sonarObjectIds,
            stakeholderIds: ids
        });
    };
    const editFollowing = () => {
        if (following) {
            follow();
        }
        else {
            unfollow();
        }
    };
    const editTags = () => {
        linkTags({
            sonarObjectIds,
            sonarObjectTagId: Number(tagId)
        });
    };
    const editInitiatives = () => {
        addToInitiative({
            initiativeId: Number(initiativeOption === null || initiativeOption === void 0 ? void 0 : initiativeOption.value),
            initiativeTitle: String(initiativeOption === null || initiativeOption === void 0 ? void 0 : initiativeOption.label),
            sonarObjectIds
        });
    };
    const editTasks = () => {
        addToTask({
            initiativeId: Number(initiativeOption === null || initiativeOption === void 0 ? void 0 : initiativeOption.value),
            initiativeTaskId: Number(taskOption === null || taskOption === void 0 ? void 0 : taskOption.value),
            sonarObjectIds
        });
    };
    const handleOnCancel = () => {
        reset();
        if (onCancel) {
            onCancel();
        }
    };
    const handleOnOk = () => {
        if (ownerId) {
            editOwner();
        }
        if (stakeholderIds) {
            editStakeholders();
        }
        if (following !== undefined) {
            editFollowing();
        }
        if (complianceCategory !== undefined ||
            dataSensitivity !== undefined ||
            fieldUsage !== undefined) {
            bulkEditFieldProperties(sonarObjectIds.length ? sonarObjectIds : selectedFields, complianceCategory, dataSensitivity, fieldUsage);
        }
        if (tagId) {
            editTags();
        }
        if (initiativeOption && !taskOption) {
            editInitiatives();
        }
        if (taskOption) {
            editTasks();
        }
        if (onOk) {
            onOk();
        }
        reset();
    };
    return (react_1.default.createElement(modals_1.PopoverModal, { className: 'data-dictionary-bulk-edit-modal', fixedWidth: true, mask: false, okButtonProps: { disabled: !okEnabled }, okText: okText, onCancel: handleOnCancel, onOk: handleOnOk, open: open, popoverPosition: popoverPosition, popoverTarget: popoverTarget, style: { height: 'calc(100vh - 200px)' }, styles: { body: { overflowY: 'scroll' } }, title: title },
        react_1.default.createElement(react_if_1.When, { condition: sonarObjectType !== sonar_ts_types_1.SonarObjectType.SalesforcePicklistValue },
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.OWNER },
                react_1.default.createElement(UserSelector_1.UserSelector, { allowClear: true, avatar: true, id: 'data-dictionary-bulk-edit-user-select', onChange: setOwnerId, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.CHANGE_OWNER, value: ownerId })),
            react_1.default.createElement(features_1.FeatureBoundary, { feature: sonar_core_1.FeatureFlag.Stakeholders },
                react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.STAKEHOLDERS },
                    react_1.default.createElement(StakeholderSelector_1.StakeholderSelector, { avatar: true, direction: 'horizontal', id: 'data-dictionary-bulk-edit-stakeholder-select', mode: 'multiple', onChange: setStakeholderIds, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.ADD_STAKEHOLDERS, value: stakeholderIds })))),
        react_1.default.createElement(react_if_1.When, { condition: followersEnabled },
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.FOLLOWING },
                react_1.default.createElement(BulkFollowCheckbox_1.BulkFollwCheckbox, { onChange: setFollowing, sonarObjectIds: sonarObjectIds, value: following }))),
        react_1.default.createElement(react_if_1.When, { condition: hasFields },
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.COMPLIANCE_CATEGORIES },
                react_1.default.createElement(tags_1.ComplianceCategoryBulkSelector, { onChange: setComplianceCategory, value: complianceCategory })),
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.DATA_SENSITIVITY },
                react_1.default.createElement(tags_1.DataSensitivityBulkSelector, { onChange: setDataSensitivity, value: dataSensitivity })),
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.FIELD_USAGE },
                react_1.default.createElement(FieldUsageBulkSelector_1.FieldUsageBulkSelector, { onChange: setFieldUsage, value: fieldUsage })),
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.TAGS },
                react_1.default.createElement(TagSelector_1.TagSelector, { onChange: setTagId, value: tagId }))),
        react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.ADD_TO_INITIATIVE },
            react_1.default.createElement(InitiativeSelector_1.InitiativeSelector, { onChange: setInitiativeOption, value: initiativeOption })),
        react_1.default.createElement(react_if_1.When, { condition: Boolean(initiativeOption) },
            react_1.default.createElement(react_if_1.If, { condition: shouldAddToTask },
                react_1.default.createElement(react_if_1.Then, null,
                    react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.ADD_TO_TASK },
                        react_1.default.createElement(TaskSelector_1.TaskSelector, { initiativeId: Number(initiativeOption === null || initiativeOption === void 0 ? void 0 : initiativeOption.value), onChange: setTaskOption, value: taskOption }))),
                react_1.default.createElement(react_if_1.Else, null,
                    react_1.default.createElement(antd_1.Button, { className: 'add-to-task-button', onClick: setShouldAddToTask.on, type: 'link' }, sonar_ts_constants_1.COPY_SPECIFIC.ADD_TO_A_TASK))))));
};
exports.DataDictionaryBulkEditModal = DataDictionaryBulkEditModal;
