"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLinkInitiativeTask = void 0;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.tasks.link.post);
function useLinkInitiativeTask() {
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreInitiativesTasksLink);
    const isProcessing = (0, useSonarSelector_1.useSonarSelector)(selectors.isRequested);
    const isSuccessful = (0, useSonarSelector_1.useSonarSelector)(selectors.isReceived);
    return {
        isProcessing,
        isSuccessful,
        request
    };
}
exports.useLinkInitiativeTask = useLinkInitiativeTask;
useLinkInitiativeTask.selectors = selectors;
