"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UptimeMonitorsDashboard = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./UptimeMonitorsDashboard.scss");
const selectors_1 = require("@lib/selectors");
const icons_1 = require("@ant-design/icons");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const text_1 = require("@ui/text");
const plots_1 = require("@ant-design/plots");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const antd_1 = require("antd");
const _hooks_1 = require("@hooks");
const dashboardSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.monitorsDashboard.get);
function mapDowntimeDays(downtimeDays, uptimeInterval) {
    const days = [];
    for (let index = 0; index < uptimeInterval; index++) {
        const down = downtimeDays.find((x) => x === index + 1);
        days.push({
            color: down ? css.deletion : css.lightGreen,
            day: index,
            up: down ? 50 : 100
        });
    }
    return days;
}
const chartConfig = {
    autoFit: true,
    color: ({ color }) => color,
    columnWidthRatio: 0.9,
    coordinate: [{
            type: 'reflectX'
        }],
    data: [],
    height: 50,
    legend: false,
    padding: 0,
    seriesField: 'color',
    slider: false,
    tooltip: false,
    xAxis: {
        label: {
            formatter: () => null
        },
        tickLine: { length: 0 }
    },
    xField: 'day',
    yAxis: {
        grid: null,
        label: {
            formatter: () => null
        }
    },
    yField: 'up'
};
exports.UptimeMonitorsDashboard = (0, react_1.memo)(({ applicationId }) => {
    const apps = (0, _hooks_1.usePulseApps)();
    const hasUptimeMonitors = (0, react_1.useMemo)(() => (applicationId ? apps.filter((app) => app.applicationId === applicationId) : apps).some((app) => app.monitors.some((monitor) => monitor.monitorTypeId === sonar_core_1.PulseMonitorType.Uptime)), [applicationId, apps]);
    const { data, isRequested } = (0, _hooks_1.useAsyncStateWithPreload)(dashboardSelectors, sonar_core_1.coreActions.requestGetCorePulseUptimeMonitorsDashboard, true, { applicationId });
    if (!hasUptimeMonitors) {
        return null;
    }
    return (react_1.default.createElement(general_1.SonarCard, { id: 'uptime-montitors-dashboard', loading: isRequested, title: sonar_ts_constants_1.PULSE_DASHBOARD.UPTIME_MONITORS, titleClassName: 'h2', useSpinnerLoading: true },
        react_1.default.createElement("div", { className: 'monitor-periods' }, data === null || data === void 0 ? void 0 : data.map((monitor) => react_1.default.createElement("div", { className: 'monitor-period', key: monitor.uptimeInterval },
            react_1.default.createElement(antd_1.Statistic, { suffix: react_1.default.createElement(general_1.StatisticDelta, { change: monitor.changePercentage, isPercent: true, precision: 2 }), title: react_1.default.createElement(antd_1.Space, { size: 8 },
                    react_1.default.createElement(text_1.SonarText, { className: 'monitor-title' }, sonar_ts_constants_1.PULSE_DASHBOARD.UPTIME_DAYS(monitor.uptimeInterval)),
                    react_1.default.createElement(antd_1.Tooltip, { title: sonar_ts_constants_1.PULSE_DASHBOARD.UPTIME_TOOLTIP },
                        react_1.default.createElement(icons_1.InfoCircleOutlined, null))), value: monitor.uptimePercentage }),
            react_1.default.createElement(plots_1.Column, Object.assign({}, chartConfig, { data: mapDowntimeDays(monitor.downtimeDays, monitor.uptimeInterval) })))))));
});
