"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateInitiative = void 0;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useAsyncState_1 = require("../useAsyncState");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.post);
function useCreateInitiative() {
    const { data, isReceived, isRequested } = (0, useAsyncState_1.useAsyncState)(selectors);
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreInitiatives);
    return {
        isProcessing: isRequested,
        isSuccessful: isReceived,
        request,
        result: data
    };
}
exports.useCreateInitiative = useCreateInitiative;
useCreateInitiative.selectors = selectors;
