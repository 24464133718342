"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateTag = void 0;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.tags.post);
/**
 * Subscribes to the create tag processing. Provides actions to request and reset, flags for status, and
 * access to the last processing result, if any.
 * @returns A {@link UseCreateTagResult} with processing flags, actions, and potential result.
 */
function useCreateTag() {
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreTags);
    const reset = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetPostCoreTags);
    const isProcessing = (0, useSonarSelector_1.useSonarSelector)(selectors.isRequested);
    const isSuccessful = (0, useSonarSelector_1.useSonarSelector)(selectors.isReceived);
    const result = (0, useSonarSelector_1.useSonarSelector)(selectors.data);
    return {
        isProcessing,
        isSuccessful,
        request,
        reset,
        result
    };
}
exports.useCreateTag = useCreateTag;
useCreateTag.selectors = selectors;
