"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PulseView = void 0;
const ApiVolumeArea_1 = require("./api-volume/ApiVolumeArea");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const PulseApplicationOverview_1 = require("./PulseApplicationOverview");
const PulseDashboard_1 = require("./PulseDashboard");
const SetupAuditTrailView_1 = require("./audit-trail/SetupAuditTrailView");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const PulseView = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const [dateRange, setDateRange] = (0, react_1.useState)({ start: (0, sonar_ts_lib_1.getPastDate)(7) });
    return (react_1.default.createElement(react_router_dom_1.Routes, null,
        react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(PulseDashboard_1.PulseDashboard, { dateRange: dateRange, onDateChange: setDateRange }), path: '' }),
        react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(PulseApplicationOverview_1.PulseApplicationOverview, { dateRange: dateRange, key: location.pathname, onDateChange: setDateRange }), path: sonar_ts_constants_1.PARAMS.ID }),
        react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(ApiVolumeArea_1.ApiVolumeArea, null), path: sonar_ts_constants_1.BASE_ROUTES.API_VOLUME }),
        react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(SetupAuditTrailView_1.SetupAuditTrailViewArea, null), path: sonar_ts_constants_1.BASE_ROUTES.SETUP_AUDIT_TRAIL })));
};
exports.PulseView = PulseView;
