"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.periscopeReducers = void 0;
const action_types_1 = require("./action_types");
const state_1 = require("../read/state");
const action_types_2 = require("../read/action_types");
const action_types_3 = require("../remove/action_types");
const reducer_1 = require("../remove/reducer");
const actions_1 = require("../../../application/actions");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
function buildNewPeriscopeArray(cards, data) {
    const { limit = 3, periscopeIndex, openLeft = false } = data, rest = __rest(data, ["limit", "periscopeIndex", "openLeft"]);
    const newPeriscope = Object.assign({}, rest);
    if (openLeft && periscopeIndex === 1) {
        return (0, sonar_ts_lib_1.take)([newPeriscope, ...cards], limit);
    }
    return (0, sonar_ts_lib_1.takeRight)([...cards.slice(0, periscopeIndex), newPeriscope], limit);
}
function periscopeReducers(state = state_1.defaultPeriscopeState, action) {
    const { type } = action;
    switch (type) {
        case action_types_3.REMOVE_ACTION_TYPES.REMOVE_PERISCOPE:
            return (0, reducer_1.removePeriscope)(action.data, state);
        case action_types_2.READ_ACTION_TYPES.RESET_PERISCOPE:
            return state_1.defaultPeriscopeState;
        case action_types_1.ACTION_TYPES.UPDATE_PERISCOPE:
            return Object.assign(Object.assign({}, state_1.defaultPeriscopeState), { cards: buildNewPeriscopeArray(state.cards, action.data) });
        case action_types_1.ACTION_TYPES.UPDATE_PERISCOPE_INFO:
            const _a = action.data, { periscopeIndex } = _a, rest = __rest(_a, ["periscopeIndex"]);
            return Object.assign(Object.assign({}, state_1.defaultPeriscopeState), { cards: (0, sonar_ts_lib_1.updateAt)(state.cards, action.data.periscopeIndex, (card) => (Object.assign(Object.assign({}, card), rest))) });
        case actions_1.ApplicationActionType.Reset:
            return Object.assign({}, state_1.defaultPeriscopeState);
        default:
            return state;
    }
}
exports.periscopeReducers = periscopeReducers;
