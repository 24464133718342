"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncStateWithPreload = void 0;
const useAsyncState_1 = require("./useAsyncState");
const useOnMount_1 = require("./useOnMount");
const useAction_1 = require("./useAction");
/**
 * Subscribes to an async state collection. Will load related data via provided action upon mount if data is
 * not available or forced via `forceLoad`.
 * @param stateSelectors A prebuilt {@link AsyncSelectors} object for a results collection state to manage.
 * @param loadAction Action factory for the load request.
 * @param forceLoad Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @param args Default arguments for the action, if any.
 * @returns A {@link UseAsyncStateWithPreloadResult} instance with the related `data`, if the data
 * `isReceived`, and if the data `isRequested`.
 */
function useAsyncStateWithPreload(stateSelectors, loadAction, forceLoad, args) {
    const { data, isReceived, isRequested } = (0, useAsyncState_1.useAsyncState)(stateSelectors);
    const load = (0, useAction_1.useAction)(loadAction);
    const shouldLoad = !(isRequested || isReceived) || forceLoad;
    (0, useOnMount_1.useOnMount)(() => {
        if (shouldLoad) {
            load(args);
        }
    });
    return {
        data,
        isReceived,
        isRequested,
        request: load
    };
}
exports.useAsyncStateWithPreload = useAsyncStateWithPreload;
