"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserReference = void 0;
const selectors_1 = require("@lib/selectors");
const useSonarSelector_1 = require("../useSonarSelector");
const mapSelector = (0, selectors_1.createSonarSelector)((state) => state.core.users.byIdMap);
const emptyMap = {};
const empty = {
    email: '',
    firstName: '',
    lastName: '',
    userId: 0
};
/**
 * Gets the shared sync {@link UserReference} for the provided user id.
 * @param userId Id of the user to lookup.
 * @returns The {@link UserReference} for the target user, or an empty object if none exists yet.
 */
function useUserReference(userId) {
    return (0, useSonarSelector_1.useSonarSelector)((state) => { var _a, _b; return (_b = ((_a = mapSelector(state).data) !== null && _a !== void 0 ? _a : emptyMap)[userId]) !== null && _b !== void 0 ? _b : empty; });
}
exports.useUserReference = useUserReference;
