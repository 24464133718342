"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePeriscopeInfo = exports.updatePeriscope = void 0;
const action_types_1 = require("./action_types");
function updatePeriscope(updatePeriscopeSync) {
    return {
        data: updatePeriscopeSync,
        type: action_types_1.ACTION_TYPES.UPDATE_PERISCOPE
    };
}
exports.updatePeriscope = updatePeriscope;
function updatePeriscopeInfo(request) {
    return {
        data: request,
        type: action_types_1.ACTION_TYPES.UPDATE_PERISCOPE_INFO
    };
}
exports.updatePeriscopeInfo = updatePeriscopeInfo;
