"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApplicationSidebar = void 0;
const _app_1 = require("@app");
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("./useAction");
const react_1 = require("react");
const useSonarSelector_1 = require("./useSonarSelector");
const selector = (0, selectors_1.createSonarSelector)((state) => { var _a; return Boolean((_a = state.application.sidebar.read.data) === null || _a === void 0 ? void 0 : _a.closed); });
function useApplicationSidebar() {
    const isClosed = (0, useSonarSelector_1.useSonarSelector)(selector);
    const update = (0, useAction_1.useAction)(_app_1.appActions.updateApplicationSidebar);
    const close = (0, react_1.useCallback)(() => {
        update({
            closed: true
        });
    }, [update]);
    const open = (0, react_1.useCallback)(() => {
        update({
            closed: false
        });
    }, [update]);
    const toggle = (0, react_1.useCallback)(() => {
        update({
            closed: !isClosed
        });
    }, [isClosed, update]);
    return {
        close,
        isClosed,
        open,
        toggle
    };
}
exports.useApplicationSidebar = useApplicationSidebar;
