"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseObjectFilter = exports.stringifyObjectFilter = exports.TIMELINE_CHANGE_TYPE_ORDER = exports.getChangeTypeToChangeLabel = exports.getChangeRecordClassName = exports.isRowExpandable = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
function isRowExpandable(record) {
    const { changeType } = record.change;
    return changeType === sonar_core_1.TimelineChangeType.Modified;
}
exports.isRowExpandable = isRowExpandable;
function getChangeRecordClassName(changeType, count) {
    return `statistic clickable ${count ? changeType : 'unmodified'}`;
}
exports.getChangeRecordClassName = getChangeRecordClassName;
function getChangeTypeToChangeLabel(changeType) {
    switch (changeType) {
        case sonar_core_1.TimelineChangeType.Discovered:
            return sonar_ts_constants_1.TIMELINE_COPY.ADDED;
        case sonar_core_1.TimelineChangeType.Modified:
            return sonar_ts_constants_1.TIMELINE_COPY.MODIFIED;
        case sonar_core_1.TimelineChangeType.Vanished:
            return sonar_ts_constants_1.TIMELINE_COPY.DELETED;
        default:
            return undefined;
    }
}
exports.getChangeTypeToChangeLabel = getChangeTypeToChangeLabel;
exports.TIMELINE_CHANGE_TYPE_ORDER = [
    sonar_core_1.TimelineChangeType.Discovered,
    sonar_core_1.TimelineChangeType.Modified,
    sonar_core_1.TimelineChangeType.Vanished
];
const stringifyObjectFilter = (filters) => Object.entries(filters).reduce((str, [key, value]) => {
    const binaryArray = exports.TIMELINE_CHANGE_TYPE_ORDER.map((type) => ((value === null || value === void 0 ? void 0 : value[type]) ? 1 : 0));
    const int = sonar_core_1.coreLib.binaryToInteger(binaryArray.join(''));
    const separator = str ? '|' : '';
    return `${str}${separator}${key}~${int}`;
}, '');
exports.stringifyObjectFilter = stringifyObjectFilter;
const parseObjectFilter = (value) => (value ?
    value.split('|').reduce((filters, filterString) => {
        if (!filterString) {
            return filters;
        }
        const [objectType, int] = filterString.split('~');
        const binaryArray = Array.from(sonar_core_1.coreLib.integerToBinary(Number(int), 3), Number);
        return Object.assign(Object.assign({}, filters), { [objectType]: exports.TIMELINE_CHANGE_TYPE_ORDER.reduce((changes, type, index) => (Object.assign(Object.assign({}, changes), { [type]: Boolean(binaryArray[index]) })), {}) });
    }, {}) :
    {});
exports.parseObjectFilter = parseObjectFilter;
