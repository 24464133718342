"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncDefaultState = void 0;
const usePrevious_1 = require("./usePrevious");
const react_1 = require("react");
function useAsyncDefaultState(defaultValue, onInitiatlize) {
    const [value, setValue] = (0, react_1.useState)(defaultValue);
    const [initialized, setInitialized] = (0, react_1.useState)(false);
    const previousDefaultValue = (0, usePrevious_1.usePrevious)(defaultValue);
    (0, react_1.useEffect)(() => {
        if (!initialized && !previousDefaultValue && defaultValue) {
            setInitialized(true);
            setValue(defaultValue);
            if (onInitiatlize) {
                onInitiatlize(defaultValue);
            }
        }
    }, [defaultValue, initialized, onInitiatlize, previousDefaultValue]);
    return [value, setValue];
}
exports.useAsyncDefaultState = useAsyncDefaultState;
