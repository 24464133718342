"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumns = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
function getItemColumn() {
    return {
        dataIndex: 'item',
        defaultSortOrder: 'ascend',
        key: 'item',
        sorter: (a, b) => a.item.localeCompare(b.item),
        title: sonar_ts_constants_1.ENTITY_LIMITS_TABLE_COPY.ITEM
    };
}
function getUsageColumn() {
    return {
        dataIndex: 'usage',
        sorter: (a, b) => a.usage - b.usage,
        title: sonar_ts_constants_1.ENTITY_LIMITS_TABLE_COPY.USAGE
    };
}
function getLimitColumn() {
    return {
        dataIndex: 'limit',
        sorter: (a, b) => a.limit - b.limit,
        title: sonar_ts_constants_1.ENTITY_LIMITS_TABLE_COPY.LIMIT
    };
}
function getPercentUsedColumn() {
    return {
        dataIndex: 'percentUsed',
        render: (value) => `${value}%`,
        sorter: (a, b) => a.percentUsed - b.percentUsed,
        title: sonar_ts_constants_1.ENTITY_LIMITS_TABLE_COPY.PERCENT_USED
    };
}
function getColumns() {
    return [
        getItemColumn(),
        getUsageColumn(),
        getLimitColumn(),
        getPercentUsedColumn()
    ];
}
exports.getColumns = getColumns;
