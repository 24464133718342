"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimelineQueryObject = exports.getChangeTypeToChangeLabel = exports.getChangeRecordClassName = exports.isRowExpandable = exports.flattenDataForLegacyDetailsTable = exports.determineChangeRowChangeType = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
function determineChangeRowChangeType(index) {
    switch (index) {
        case 0:
            return sonar_core_1.TimelineChangeType.Discovered;
        case 1:
            return sonar_core_1.TimelineChangeType.Modified;
        case 2:
            return sonar_core_1.TimelineChangeType.Vanished;
        default:
            return undefined;
    }
}
exports.determineChangeRowChangeType = determineChangeRowChangeType;
function flattenDataForLegacyDetailsTable(timelineDetails) {
    return timelineDetails.map((result) => {
        const { change } = result, rest = __rest(result, ["change"]);
        return Object.assign(Object.assign({}, change), rest);
    });
}
exports.flattenDataForLegacyDetailsTable = flattenDataForLegacyDetailsTable;
function isRowExpandable(record) {
    const { changeType } = record;
    return changeType === sonar_core_1.TimelineChangeType.Modified;
}
exports.isRowExpandable = isRowExpandable;
function getChangeRecordClassName(changeType, count) {
    return `statistic ${count ? changeType : 'unmodified'}`;
}
exports.getChangeRecordClassName = getChangeRecordClassName;
function getChangeTypeToChangeLabel(changeType) {
    switch (changeType) {
        case sonar_core_1.TimelineChangeType.Discovered:
            return sonar_ts_constants_1.TIMELINE_COPY.ADDED;
        case sonar_core_1.TimelineChangeType.Modified:
            return sonar_ts_constants_1.TIMELINE_COPY.MODIFIED;
        case sonar_core_1.TimelineChangeType.Vanished:
            return sonar_ts_constants_1.TIMELINE_COPY.DELETED;
        default:
            return undefined;
    }
}
exports.getChangeTypeToChangeLabel = getChangeTypeToChangeLabel;
function getTimelineQueryObject(queryString, timelineState) {
    const removedMarkQuery = queryString.substring(queryString.indexOf('?') + 1);
    const parsedQuery = (0, sonar_ts_lib_1.parseQueryString)(removedMarkQuery);
    const { count, end, offset, start, tzoffset, objectTypeFilters, lastModifiedByUsernameFilters, changeTypeFilters, system } = parsedQuery;
    const { count: stateCount, end: stateEnd, offset: stateOffset, start: stateStart, system: stateSystem, tzoffset: stateTzOffset } = timelineState;
    const formattedQuery = Object.assign(Object.assign({}, parsedQuery), { changeTypeFilters: (0, sonar_ts_lib_1.toArray)(changeTypeFilters), count: Number(count) || stateCount, end: end || stateEnd, lastModifiedByUsernameFilters: (0, sonar_ts_lib_1.toArray)(lastModifiedByUsernameFilters), objectTypeFilters: (0, sonar_ts_lib_1.toArray)(objectTypeFilters), offset: Number(offset) || stateOffset, start: start || stateStart, system: system === undefined ? stateSystem : system, tzoffset: Number(tzoffset) || stateTzOffset });
    return formattedQuery;
}
exports.getTimelineQueryObject = getTimelineQueryObject;
