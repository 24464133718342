"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateView = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const ViewForm_1 = require("./ViewForm");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const createViewSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataDictionary.views.post);
const CreateView = ({ onFinish, queryParams }) => {
    const { data, isReceived, isRequested } = (0, _hooks_1.useAsyncState)(createViewSelectors);
    const createView = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreDataDictionaryViews);
    const resetCreate = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPostCoreDataDictionaryViews);
    const nameRef = (0, react_1.useRef)('');
    (0, _hooks_1.useOnUnmount)(resetCreate);
    const onFinishRef = (0, react_1.useRef)(onFinish);
    onFinishRef.current = onFinish;
    (0, react_1.useEffect)(() => {
        if (isReceived && data) {
            onFinishRef.current(data.viewId, nameRef.current);
        }
    }, [data, isReceived]);
    return (react_1.default.createElement(general_1.QuickAdd, { form: ViewForm_1.ViewForm, onSubmit: (viewName) => {
            var _a, _b, _c;
            nameRef.current = viewName;
            createView({
                applicationId: queryParams.applicationId,
                columns: (_a = queryParams.columns) !== null && _a !== void 0 ? _a : '',
                filter: (_b = queryParams.filters) !== null && _b !== void 0 ? _b : '',
                ordering: (_c = queryParams.ordering) !== null && _c !== void 0 ? _c : '',
                sonarObjectId: queryParams.sonarObjectId,
                sonarObjectTypeId: queryParams.sonarObjectType ?
                    (0, sonar_ts_lib_1.getSonarObjectTypeId)(queryParams.sonarObjectType) :
                    undefined,
                tagId: queryParams.tagId,
                viewName: viewName === null || viewName === void 0 ? void 0 : viewName.trim()
            });
        }, processing: isRequested, toggleProps: { id: 'save-filters-new-view-button' }, typeLabel: sonar_ts_constants_1.COPY_SPECIFIC.VIEW }));
};
exports.CreateView = CreateView;
