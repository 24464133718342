"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDataDictionaryWorkflow = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_router_dom_1 = require("react-router-dom");
function useDataDictionaryWorkflow() {
    const { pathname } = (0, react_router_dom_1.useLocation)();
    const lowerPath = pathname.toLowerCase();
    return lowerPath.startsWith(sonar_ts_constants_1.FULL_ROUTES.DATA_DICTIONARY);
}
exports.useDataDictionaryWorkflow = useDataDictionaryWorkflow;
