"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGlobalSelect = exports.globalSelectionSelector = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("../lib/selectors");
const useAction_1 = require("./useAction");
const useSonarSelector_1 = require("./useSonarSelector");
const react_1 = require("react");
exports.globalSelectionSelector = (0, selectors_1.createSonarSelector)((state) => state.core.selections.read);
function useGlobalSelect() {
    const coreSelection = (0, useSonarSelector_1.useSonarSelector)(exports.globalSelectionSelector).data;
    const getGlobalSelection = (0, react_1.useCallback)((options) => {
        var _a, _b;
        return Object.entries((_b = coreSelection === null || coreSelection === void 0 ? void 0 : coreSelection[(_a = options === null || options === void 0 ? void 0 : options.namespace) !== null && _a !== void 0 ? _a : 'global']) !== null && _b !== void 0 ? _b : {})
            .filter(([, { sonarObjectType }]) => ((options === null || options === void 0 ? void 0 : options.types) ? options === null || options === void 0 ? void 0 : options.types.includes(sonarObjectType) : true))
            .reduce((allIds, [id]) => [...allIds, Number(id)], []);
    }, [coreSelection]);
    const globalSelection = (0, react_1.useMemo)(() => getGlobalSelection(), [getGlobalSelection]);
    const addSelection = (0, useAction_1.useAction)(sonar_core_1.coreActions.addCoreSelections);
    const removeSelection = (0, useAction_1.useAction)(sonar_core_1.coreActions.removeCoreSelections);
    return {
        addSelection,
        getGlobalSelection,
        globalSelection,
        removeSelection,
        totalCount: globalSelection.length
    };
}
exports.useGlobalSelect = useGlobalSelect;
