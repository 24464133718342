"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSonarShallowSelector = exports.useSonarSelector = void 0;
const react_redux_1 = require("react-redux");
function useSonarSelector(selectorFn, equalityFn) {
    return (0, react_redux_1.useSelector)(selectorFn, equalityFn);
}
exports.useSonarSelector = useSonarSelector;
function useSonarShallowSelector(selectorFn) {
    return useSonarSelector(selectorFn, react_redux_1.shallowEqual);
}
exports.useSonarShallowSelector = useSonarShallowSelector;
