"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSalesforceOrganizations = void 0;
const react_1 = require("react");
const useOrganizations_1 = require("./useOrganizations");
function useSalesforceOrganizations() {
    const organizations = (0, useOrganizations_1.useOrganizations)();
    return (0, react_1.useMemo)(() => organizations.filter((org) => org.hasSalesforceData ||
        org.salesforceSyncInProgress ||
        org.salesforceSyncFailed ||
        (org.instanceUrl && org.instanceUrl.includes('salesforce'))), [organizations]);
}
exports.useSalesforceOrganizations = useSalesforceOrganizations;
