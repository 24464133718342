"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLogoColors = exports.getImageColorCounts = exports.rgbToHsl = exports.rgbToHex = exports.hexToRgb = void 0;
const decendingCount = (a, b) => b.count - a.count;
const ascendingCount = (a, b) => a.count - b.count;
function hexToRgb(hex) {
    const result = (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/iu).exec(hex);
    return result ? {
        b: parseInt(result[3], 16),
        g: parseInt(result[2], 16),
        r: parseInt(result[1], 16)
    } : {
        b: 0,
        g: 0,
        r: 0
    };
}
exports.hexToRgb = hexToRgb;
function rgbToHex(rgb) {
    // eslint-disable-next-line no-bitwise
    return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;
}
exports.rgbToHex = rgbToHex;
function rgbToHsl(rgb) {
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;
    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = ((g - b) / d) + (g < b ? 6 : 0);
                break;
            case g:
                h = ((b - r) / d) + 2;
                break;
            case b:
                h = ((r - g) / d) + 4;
                break;
            default:
                h = ((g - b) / d) + 0;
                break;
        }
        h /= 6;
    }
    return {
        h,
        l,
        s
    };
}
exports.rgbToHsl = rgbToHsl;
function getImageColorCounts(imageUrl_1) {
    return __awaiter(this, arguments, void 0, function* (imageUrl, blockSize = 1) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
            return {};
        }
        const response = yield fetch(imageUrl);
        const blob = yield response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        const imgData = yield new Promise((resolve) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0);
                try {
                    resolve(context.getImageData(0, 0, canvas.width, canvas.height));
                }
                catch (e) {
                    resolve(null);
                }
            };
        });
        if (!imgData) {
            return {};
        }
        const colorCounts = {};
        for (let i = 0; i < imgData.data.length; i += blockSize * 4) {
            const rgb = {
                b: imgData.data[i + 2],
                g: imgData.data[i + 1],
                r: imgData.data[i]
            };
            const key = rgbToHex(rgb);
            colorCounts[key] = (colorCounts[key] || 0) + 1;
        }
        return colorCounts;
    });
}
exports.getImageColorCounts = getImageColorCounts;
function getLogoColors(logoUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        const lightnessThreshold = 0.85;
        const darknessThreshold = 0.3;
        const saturationThreshold = 0.3;
        const colorCounts = yield getImageColorCounts(logoUrl);
        const colors = Object.entries(colorCounts).map(([hex, count]) => {
            const rgb = hexToRgb(hex);
            const hsl = rgbToHsl(rgb);
            return {
                count,
                hex,
                hsl,
                rgb
            };
        });
        const isWithinSaturationThreshold = (lightness, saturation) => lightness > darknessThreshold && lightness < lightnessThreshold && saturation > saturationThreshold;
        const saturatedColors = colors.filter(({ hsl }) => isWithinSaturationThreshold(hsl.l, hsl.s));
        const desaturatedColors = colors.filter(({ hsl }) => !isWithinSaturationThreshold(hsl.l, hsl.s));
        return [
            ...saturatedColors.sort(decendingCount),
            ...desaturatedColors.sort(ascendingCount)
        ];
    });
}
exports.getLogoColors = getLogoColors;
