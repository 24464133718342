"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAsyncCollectionStateWithPreloadHook = exports.createAsyncStateWithPreloadHook = void 0;
const useAsyncState_1 = require("@hooks/useAsyncState");
const react_1 = require("react");
const useOnMount_1 = require("@hooks/useOnMount");
const usePrevious_1 = require("@hooks/usePrevious");
const useAction_1 = require("@hooks/useAction");
const selectors_1 = require("./selectors");
function createAsyncStateWithPreloadHook(options) {
    let loadingLock = false;
    const selectors = (0, selectors_1.createAsyncSelectors)(options.selector);
    const { defaultRequest, loadAction } = options;
    const useAsyncStateWithPreloadInternal = function (refresh = false, initialArgs) {
        const { data, isReceived, isRequested } = (0, useAsyncState_1.useAsyncState)(selectors);
        const previousIsRequested = (0, usePrevious_1.usePrevious)(isRequested, isRequested);
        const load = (0, useAction_1.useAction)(loadAction);
        const shouldLoad = !(isRequested || isReceived) || refresh;
        (0, useOnMount_1.useOnMount)(() => {
            if (shouldLoad && !loadingLock) {
                loadingLock = true;
                load(initialArgs || defaultRequest);
            }
        });
        (0, react_1.useEffect)(() => {
            if (!isRequested && previousIsRequested) {
                loadingLock = false;
            }
        }, [isRequested, previousIsRequested]);
        return options.useTransform({
            data,
            isReceived,
            isRequested,
            request: load
        });
    };
    useAsyncStateWithPreloadInternal.defaultRequest = defaultRequest;
    useAsyncStateWithPreloadInternal.selectors = selectors;
    useAsyncStateWithPreloadInternal.loadAction = loadAction;
    return useAsyncStateWithPreloadInternal;
}
exports.createAsyncStateWithPreloadHook = createAsyncStateWithPreloadHook;
function createAsyncCollectionStateWithPreloadHook(options) {
    return createAsyncStateWithPreloadHook(Object.assign(Object.assign({}, options), { useTransform: (result) => {
            var _a;
            return options.useTransform(Object.assign(Object.assign({}, result), { data: (_a = result.data) === null || _a === void 0 ? void 0 : _a.results }));
        } }));
}
exports.createAsyncCollectionStateWithPreloadHook = createAsyncCollectionStateWithPreloadHook;
