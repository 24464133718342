"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleEscape = exports.handleEnter = exports.trigger = exports.once = exports.off = exports.on = exports.ifClickHandler = exports.haltEvent = exports.haltKeyboardEventPropagation = exports.haltClickEventPropagation = void 0;
function haltClickEventPropagation(event) {
    event === null || event === void 0 ? void 0 : event.stopPropagation();
}
exports.haltClickEventPropagation = haltClickEventPropagation;
function haltKeyboardEventPropagation(event) {
    event === null || event === void 0 ? void 0 : event.stopPropagation();
}
exports.haltKeyboardEventPropagation = haltKeyboardEventPropagation;
function haltEvent(event) {
    event === null || event === void 0 ? void 0 : event.preventDefault();
    event === null || event === void 0 ? void 0 : event.stopPropagation();
}
exports.haltEvent = haltEvent;
function ifClickHandler(clickHandler, arg) {
    if (clickHandler) {
        return (event) => {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            clickHandler(arg);
        };
    }
    return undefined;
}
exports.ifClickHandler = ifClickHandler;
function on(eventType, listener) {
    document.addEventListener(eventType, listener);
}
exports.on = on;
function off(eventType, listener) {
    document.removeEventListener(eventType, listener);
}
exports.off = off;
function once(eventType, listener) {
    function handleEventOnce(event) {
        listener(event);
        off(eventType, handleEventOnce);
    }
    on(eventType, handleEventOnce);
}
exports.once = once;
function trigger(eventType, data) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}
exports.trigger = trigger;
function handleEnter(handler) {
    return function (e) {
        if (e.code === 'Enter') {
            handler();
        }
    };
}
exports.handleEnter = handleEnter;
function handleEscape(handler) {
    return function (e) {
        if (e.code === 'Escape') {
            handler();
        }
    };
}
exports.handleEscape = handleEscape;
