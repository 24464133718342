"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePeriscopeMetadata = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const useApiResource_1 = require("../useApiResource");
const react_1 = require("react");
function usePeriscopeMetadata(sonarObjectId, sonarObjectType) {
    const targetUri = (0, react_1.useMemo)(() => (0, sonar_ts_lib_1.getPeriscopeMetadataRouteUri)(sonarObjectType, sonarObjectId), [sonarObjectId, sonarObjectType]);
    const { loading, resource } = (0, useApiResource_1.useApiResource)(targetUri);
    return {
        loading,
        metadata: resource
    };
}
exports.usePeriscopeMetadata = usePeriscopeMetadata;
