"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePeriscopeCount = void 0;
const selectors_1 = require("@lib/selectors");
const useSonarSelector_1 = require("../useSonarSelector");
const selector = (0, selectors_1.createSonarSelector)((state) => state.application.periscope.cards.length);
function usePeriscopeCount() {
    return (0, useSonarSelector_1.useSonarSelector)(selector);
}
exports.usePeriscopeCount = usePeriscopeCount;
