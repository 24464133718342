"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnboardingProgress = void 0;
const selectors_1 = require("../lib/selectors");
const useAction_1 = require("./useAction");
const useAsyncState_1 = require("./useAsyncState");
const useAuthToken_1 = require("./useAuthToken");
const useOnMount_1 = require("./useOnMount");
const sonar_core_1 = require("@sonar-software/sonar-core");
const progressSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.onboarding.progress.get);
function useOnboardingProgress() {
    const { isLoggedIn } = (0, useAuthToken_1.useAuthToken)();
    const { data, isReceived, isRequested } = (0, useAsyncState_1.useAsyncState)(progressSelectors);
    const loadProgress = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestGetCoreOnboardingProgress);
    const updateProgress = (0, useAction_1.useAction)(sonar_core_1.coreActions.updateOnboardingProgress);
    (0, useOnMount_1.useOnMount)(() => {
        if (isLoggedIn && !isReceived && !isRequested) {
            loadProgress();
        }
    });
    return {
        loading: !isReceived || !isLoggedIn,
        progress: data,
        updateProgress
    };
}
exports.useOnboardingProgress = useOnboardingProgress;
