"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authReducers = exports.defaultAuthState = void 0;
const actions_1 = require("./actions");
exports.defaultAuthState = { token: '' };
function authReducers(state = exports.defaultAuthState, action) {
    const { type } = action;
    switch (type) {
        case actions_1.AuthActionType.Clear:
            return Object.assign({}, exports.defaultAuthState);
        case actions_1.AuthActionType.Update:
            return {
                token: action.data
            };
        default:
            return state;
    }
}
exports.authReducers = authReducers;
